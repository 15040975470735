// client/src/pages/Dashboard/Dashboard.js
/*global chrome*/

import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import SubscriptionModal from "./SubscriptionModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import ToggleButtons from "../common/ToggleButtons";
import RadialProgressBar from "./RadialProgressBar";
import StackedAreaChart from "./StackedAreaChart";
import CardBalance from "./CardBalance";
import CustomLineChart from "./CustomLineChart";
import DailyVideoIdeas from "../common/DailyVideoIdeas ";
import Cookies from "js-cookie"; // You may need to install this library
import DashboardComponent from "./DashboardComponent";
import TileSquareComponent from "../common/TileSquareComponent";
import RecTangleContentBox from "../common/RecTangleContentBox";
import { callApi } from "../../utils/api";
import useApiService from "../../actions/useApiService";
import Redeemtions from "../redeemtions";
import Modal from "react-modal";
import MyModal from "../common/MyModal";
import Button from "../common/Button";
import { baseextensionId } from "../../utils/config";
import { driver } from "driver.js";
import "driver.js/dist/driver.css"; // Still import the CSS as usual

// Set the app element for accessibility
Modal.setAppElement("#root");
// import CustomConeBarChart from "../common/CustomConeBarChart";
// import YouTubeChannelChart from "../common/YouTubeChannelChart";

const Dashboard = ({ userInfo, load }) => {
  console.log("userInfo");
  const { TrackMonitization } = useApiService();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [greeting, setGreeting] = useState("");
  const [analyticsData, setAnalyticsData] = useState(null);
  const [viewsanalyticsData, setViewsAnalyticsData] = useState([]);

  const [analyticsDataFilter, setAnalyticsDataFilter] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [betaLoading, setBetaLoading] = useState(false);
  const [betadata, setBetaData] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [viewsAnalyticsLoading, setViewsAnalyticsLoading] = useState(false);

  const [videoIdeasLoading, setVideoIdeasLoading] = useState(false);
  const [topvideoLoading, setTopVideoLoading] = useState(false);

  const [filtertext, setFiltertext] = useState("Month");
  const [videosIdeas, setVideosIdeas] = useState([]);
  const [topvideos, setTopVideos] = useState([]);

  const [activeButton, setActiveButton] = useState(1); // Initial active button
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(null);
  const [redeemtoken, setRedeemtoken] = useState("");
  const [showRedeem, setShowRedeem] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenSub, setModalIsOpenSub] = useState(false);
  const [modalIsOpenNotif, setModalIsOpenNotif] = useState(false);
  const [singleChart, setSingleChart] = useState("");

  const {
    subscriptionStatus,
    hasAccess,
    picture,
    name,
    channels,
    uasageMetrics,
  } = userInfo || {};
  const { description, tags, title } = uasageMetrics || {};
  const { betaAccess, channelVerified, channelThumbnailUrl, channelTitle } =
    (channels && channels[0]) || [];
  const { keywordsBetaAccess, keywordsRankingBetaAccess } = betaAccess || {};
  console.log("setShowRedeem", showRedeem);

  const [tourStarted, setTourStarted] = useState(channels?.length == 0);

  const startTour = () => {
    const driverObj = driver({
      showProgress: true,
      steps: [
        {
          element: ".credits.dashboard-tile-outer",
          popover: {
            title: "TubeInsights Credits",
            description:
              "Manage your TubeInsights credits for premium features.",
          },
        },
        {
          element: ".dashboard-tile-left.connect-Channel",
          popover: {
            title: "Connect Your Channel",
            description:
              "Link your YouTube channel for personalized analytics.",
          },
        },
        {
          element: ".dashboard-tile-outer-middle",
          popover: {
            title: "Analytics Chart",
            description: "Track your channel's performance metrics.",
          },
        },
        {
          element: ".dashboard-tile-outer.Monetization-Tracker",
          popover: {
            title: "Monetization Tracker",
            description: "View your revenue and monetization status.",
          },
        },
        {
          element: ".Subscribers-overview.dashboard-tile-outer",
          popover: {
            title: "Subscribers Overview",
            description: "Monitor your subscriber growth trends.",
          },
        },
        {
          element: ".dashboard-tile-outer-middle.DailyVideoIdeas",
          popover: {
            title: "Daily Video Ideas",
            description: "Get tailored video content suggestions.",
          },
        },
        {
          element: ".channel-status.dashboard-tile-outer",
          popover: {
            title: "Channel Status",
            description: "Check your channel's health and performance.",
          },
        },
      ],
    });

    // Start the tour
    driverObj.drive();
  };

  useEffect(() => {
    if (!tourStarted) {
      startTour(); // Start the tour automatically when the component mounts
      setTourStarted(true); // To ensure the tour starts only once
    }
  }, [tourStarted]);

  useEffect(() => {
    // Get the current URL search parameters
    const params = new URLSearchParams(window.location.search);
    const tokenParam = params.get("token");
    console.log("tokenParam", tokenParam);
    if (params.has("subscribe")) {
      setModalIsOpenSub(true);
    }
    if (tokenParam) {
      // Both redeem and token parameters are present
      setRedeemtoken(tokenParam);
      setShowRedeem(true);

      // Optional: Remove query parameters from URL after processing
      // window.history.replaceState({}, document.title, "/dashboard");
    }
  }, []);

  useEffect(() => {
    const checkExtensionInstallation = async () => {
      try {
        if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
          chrome.runtime.sendMessage(
            baseextensionId,
            // "ecnepininffodhhimnlocaehgpmcnnlk",

            { message: "check_installation" },
            function (response) {
              console.log("response33", response);
              if (response?.message === "successfully") {
                setIsExtensionInstalled(false);
                console.log("lol");
              } else {
                setIsExtensionInstalled(false);
                console.log("lol2");
              }
            }
          );
        } else {
          setIsExtensionInstalled(true);
          console.log("lol3");
        }
      } catch (error) {
        setIsExtensionInstalled(true);
        console.log("lol4");
      }
    };

    checkExtensionInstallation();
  }, []);

  useEffect(() => {
    // if (!userInfo) {
    //   Cookies.remove("token");

    //   // fetchUserDataFromApi();
    // } else {
    setGreeting(determineTimeOfDay());
    if (userInfo.channels && userInfo.channels.length > 0) {
      FetchUserDashBoarddata();
    }
    // }
  }, [userInfo]);

  const FetchUserDashBoarddata = () => {
    fetchAnalyticsData();
    fetchAnalyticsDataByfilter("Week");
    fetchViewAnalytics();
    handleBetaAccess();
    VideosIdeas();
    TopVideos();
  };

  const fetchAnalyticsData = async () => {
    try {
      setLoading(true);
      const email = userInfo?.email; // Provide the email here
      const params = { email, timeframe: "month" };
      const data = await callApi("POST", "/analytics/channel", params);
      // const dataa = await callApi("POST", "/monitizationtracker", params);
      const dataa = await TrackMonitization({ email });

      setAnalyticsData(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchAnalyticsDataByfilter = async (timeframe) => {
    try {
      setFilterLoading(true);
      const email = userInfo?.email; // Provide the email here
      const params = { email, timeframe: timeframe };
      const data = await callApi("POST", "/analytics/channelstats", params);
      setAnalyticsDataFilter(data);
      setFilterLoading(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchViewAnalytics = async (timeframe) => {
    try {
      setViewsAnalyticsLoading(true);
      const email = userInfo?.email; // Provide the email here
      const params = { email, timeframe: timeframe };
      const data = await callApi("POST", "/views/analytics", params);
      setViewsAnalyticsData(data);
      setViewsAnalyticsLoading(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleBetaAccess = async (flag) => {
    try {
      setBetaLoading(true);
      const email = userInfo?.email; // Provide the email here
      const params = {
        email: email,
        flags: {
          [flag]: true,
        },
      };
      const data = await callApi("POST", "/beta-access", params);
      setBetaLoading(false);
      setBetaData(data?.betaAccess);
    } catch (error) {
      setError(error.message);
    }
  };
  const TopVideos = async (fetchNewIdeas) => {
    try {
      setTopVideoLoading(true);
      const email = userInfo?.email; // Provide the email here
      const params = { email, fetchNewIdeas: fetchNewIdeas };
      const data = await callApi("POST", "/topvideos", params);
      setTopVideos(data?.topVideos);
      setTopVideoLoading(false);
    } catch (error) {
      setError(error.message);
    }
  };
  const VideosIdeas = async (fetchNewIdeas) => {
    try {
      setVideoIdeasLoading(true);
      const email = userInfo?.email; // Provide the email here
      const params = { email, fetchNewIdeas: fetchNewIdeas };
      const data = await callApi("POST", "/videoIdeas", params);
      setVideosIdeas(data?.additional_title_ideas);
      setVideoIdeasLoading(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const determineTimeOfDay = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  const handlegetChanelStats = (buttonNumber) => {
    console.log("buttonNumber", buttonNumber);

    fetchAnalyticsDataByfilter(buttonNumber == 1 ? "Week" : "Month");
  };

  const handleGetMoreIdeas = () => {
    VideosIdeas(true);
  };

  const openModal = () => setModalIsOpen(true);

  const closeModal = () => setModalIsOpen(false);

  const openSubModal = () => setModalIsOpenSub(true);

  const closeSubModal = () => setModalIsOpenSub(false);

  const openNotifModal = () => setModalIsOpenNotif(true);

  const closeNotifModal = () => setModalIsOpenNotif(false);

  // Example of large JSX content
  const profileDetails = (
    <div>
      <p>Name: {name}</p>
      <p>Email: {userInfo?.email}</p>
      <p>Channel: {channels?.[0]?.channelTitle || "Not Connected"}</p>
      <div className={`upgrade-container ${hasAccess ? "not-allowed" : ""}`}>
        <button
          className="upgrade-button "
          onClick={() => {
            openSubModal();
          }}
        >
          {hasAccess ? "Premium" : "Upgrade"}
        </button>
      </div>
      {/* Add more details as needed */}
    </div>
  );
  const notifications = [
    "Your account has been created.",
    "You have a new message.",
  ];

  const notificationContent = (
    <div className="notification-content">
      {notifications.length > 0 ? (
        <ul className="notification-list">
          {notifications.map((notification, index) => (
            <li key={index} className="notification-item">
              {notification}
            </li>
          ))}
        </ul>
      ) : (
        <p>No notifications</p>
      )}
    </div>
  );

  return (
    <>
      {showRedeem ? (
        <Redeemtions token={redeemtoken} />
      ) : (
        <>
          {/* <DashboardComponent /> */}
          {/* <div className="dashboard-tile-outer-avatar d-none">
          <div className="header-container">
            <div className="header-content">
              <div className="icon-container">
                <div className="bell-icon">&#128276;</div>
              </div>
              <div className="avatar-container" onClick="openSettingsPopup()">
                <div className="avatar-ring">
                  <img
                    src={channelVerified ? channelThumbnailUrl : picture}
                    alt="Profile"
                    className="avatar-image"
                  />
                </div>
              </div>
            </div>
            <div className="settings-popup"></div>
          </div>
        </div> */}
          {isExtensionInstalled && (
            <div className="title-row gre">
              <div className="title-row-content">
                It looks like you don't have our Chrome extension installed.
                Please download it to use our product effectively.
              </div>
              <button
                className="redirect-button"
                onClick={() => {
                  window.location.href =
                    "https://chrome.google.com/webstore/detail/ecnepininffodhhimnlocaehgpmcnnlk";
                }}
              >
                Add Chrome Extension
              </button>
            </div>
          )}

          <div
            className={`main-dashboard ${load ? " access-filter blink " : ""} `}
          >
            {" "}
            <div
              className={`right-main-dashboard-left ${false ? "d-none" : ""}`}
            >
              <div className="dashboard-tile-outer ">
                <div className="dashboard-tile-rec-chart-bar  ">
                  {viewsanalyticsData.length === 0 ? (
                    // <div className="coming-soon-2 blink">
                    <div
                      className={`coming-soon-2 ${
                        channelVerified ? "blink" : ""
                      }`}
                    >
                      <h1>
                        {" "}
                        {channelVerified ? "Analysing..." : "Creating... "}
                      </h1>
                    </div>
                  ) : (
                    <CustomLineChart viewsanalyticsData={viewsanalyticsData} />
                  )}
                </div>
              </div>
              <div className="credits dashboard-tile-outer ">
                <div className="dashboard-tile-square">
                  <div class="coming-soon-container-2">
                    <TileSquareComponent
                      progressData={[
                        { title: "Titles", count: title || 0 },
                        { title: "Desc", count: description || 0 },
                        { title: "Tags", count: tags || 0 },
                      ]}
                      titleCount={title || "0"}
                      descriptionCount={description || "0"}
                      tagsCount={tags || "0"}
                      title="TubeInsights Credits"
                      buttonTitle={150}
                      loading={loading}
                      subtitle=""
                      message=""
                    />{" "}
                  </div>
                </div>
              </div>{" "}
              <div className="channel-status dashboard-tile-outer">
                {" "}
                <div className="dashboard-tile-square">
                  {" "}
                  <div class="coming-soon-container-2">
                    <TileSquareComponent
                      title="Channel Status"
                      subscribersRatio={
                        analyticsDataFilter?.getChannelSubscribers
                          ?.totalSubscribers || "0"
                      }
                      watchTimeRatio={
                        analyticsDataFilter?.getTotalWatchTime || "0"
                      }
                      retentionRatio={
                        analyticsDataFilter?.getTotal_ChannelViews || "0"
                      }
                      ViewscountRatio={
                        analyticsDataFilter?.getTotal_ChannelViews || "0"
                      }
                      ChannelStats={true}
                      loading={filterLoading}
                      analyticsData={analyticsData}
                      button1Text="Week"
                      button2Text="Month"
                      activeButton={activeButton}
                      handlegetChanelStats={handlegetChanelStats}
                    />
                  </div>{" "}
                </div>
              </div>{" "}
              <div className="dashboard-tile-outer">
                <div className="dashboard-tile-square">
                  <div className="box-header"></div>{" "}
                  <div class="coming-soon-container-2">
                    <TileSquareComponent
                      title="Gender Demographics"
                      demographics={true}
                      loading={loading}
                      analyticsData={analyticsData}
                    />{" "}
                  </div>
                </div>
              </div>{" "}
              {/* <div className="dashboard-tile-outer">
              <div className="dashboard-tile-rec-chart-bar">
                <CustomLineChart />
              </div>
            </div> */}
              {/* <div className="dashboard-tile-outer">
              <div className="dashboard-tile-square">
                <div class="coming-soon-container-2">
                  <TileSquareComponent
                    title="Subscribers overview"
                    barchart={true}
                    subtitle="Current best times to post on Monday are 3pm - 6pm"
                    buttonTitle="Upgrade"
                  />
                </div>
              </div>
            </div>{" "} */}
              {/* <div className="dashboard-tile-outer">
              <div className="dashboard-tile-rec-chart-bar">
                <h1>Coming Soon</h1>
              </div>
            </div> */}
              <div className="dashboard-tile-outer">
                <div className="dashboard-tile-square">
                  <div class="coming-soon-container-2">
                    <TileSquareComponent
                      buttonTitle={
                        betadata?.keywordsRankingBetaAccess
                          ? "Access pending"
                          : "Beta Access"
                      }
                      className={
                        betadata?.keywordsRankingBetaAccess ? "green" : ""
                      }
                      disabled={betadata?.keywordsRankingBetaAccess}
                      loading={betaLoading}
                      handleButtonClick={handleBetaAccess}
                      flag="keywordsRankingBetaAccess"
                      title=" keywords Ranking"
                      inputPlaceholder="Search Keywords"
                    />{" "}
                  </div>
                </div>
              </div>{" "}
              {/* <div className="dashboard-tile-outer">
              <div className="dashboard-tile-square">
                <div class="coming-soon-container-2">
                  <TileSquareComponent
                    buttonTitle="Manage"
                    title="Top keyword opportunities"
                    subtitle="Select keywords in your niche to find the best keywords opportunities."
                  />{" "}
                </div>
              </div>
            </div>{" "} */}
              {/* <div className="dashboard-tile-outer">
              {" "}
              <div className="dashboard-tile-rec not-allowed">
                {" "}
                <div class="coming-soon-container-2">
                  <h1>Coming Soon</h1>
                </div>{" "}
              </div>
            </div> */}
              {/* <div className="dashboard-tile-outer">
              <div className="dashboard-tile-square">
                <div class="coming-soon-container-2">
                  <TileSquareComponent
                    title="Top keyword opportunities"
                    subscribersRatio="400"
                    watchTimeRatio="5"
                    retentionRatio="6"
                    ViewscountRatio="12534"
                    buttonTitle="Upgrade"
                  />
                </div>
              </div>
            </div>{" "} */}
              {/* <div className="dashboard-tile-outer">
              {" "}
              <div className="dashboard-tile-rec not-allowed">
                {" "}
                <div class="coming-soon-container-2">
                  <h1>Coming Soon</h1>
                </div>{" "}
              </div>
            </div> */}
            </div>
            <div className="left-main-dashboard">
              <div className="dashboard-container">
                <div className="dashboard-tile-left-outer">
                  <div className="dashboard-content">
                    <div className="dashboard-heading">
                      {`${greeting} ${
                        channelVerified ? channelTitle : name || ""
                      }`}{" "}
                    </div>
                    {/* <div className="upgrade-container">
                      <button
                        className="upgrade-button"
                        onClick={() => {
                          openSubModal();
                        }}
                      >
                        {hasAccess ? "Premium" : "Upgrade"}
                      </button>
                    </div> */}

                    <input
                      type="text"
                      placeholder="Search..."
                      className="search-bar"
                    />
                  </div>
                </div>
              </div>
              <div className="dashboard-tile-left connect-Channel">
                {" "}
                <div class="coming-soon-container-2">
                  <RecTangleContentBox connectedChannel={channelVerified} />
                </div>{" "}
              </div>

              <div className="dashboard-tile-outer-middle">
                <div className="dashboard-tile-left-chart-2">
                  <div className="chart-Btns">
                    <ToggleButtons
                      handleClick={(buttonNumber) => {
                        setSingleChart(buttonNumber);
                      }}
                      button1Text=""
                      button2Text=""
                    />
                  </div>
                  {viewsanalyticsData.length === 0 ? (
                    <div
                      className={`coming-soon ${
                        channelVerified ? "blink" : ""
                      }`}
                    >
                      <h1>
                        {channelVerified ? "Analysing..." : "Connect Channel"}
                      </h1>
                    </div>
                  ) : (
                    <StackedAreaChart
                      singleChart={singleChart}
                      viewsanalyticsData={viewsanalyticsData}
                    />
                  )}

                  {/* <div className="dashboard-content-chart">
                </div> */}
                </div>
              </div>

              <div className="dashboard-tile-outer-middle DailyVideoIdeas">
                <div className="dashboard-tile-left-chart">
                  <div className="dashboard-content-chart">
                    <div className="dashboard-heading"></div>
                    <div className="dashboard-heading"></div>
                  </div>
                  {/* <StackedAreaChart />{" "} */}

                  <DailyVideoIdeas
                    buttonTitle="More Ideas"
                    title="Daily Video Ideas"
                    loading={videoIdeasLoading}
                    handleButtonClick={handleGetMoreIdeas}
                    videoTitles={videosIdeas}
                    channelVerified={channelVerified}
                  />
                </div>
              </div>
              <div className="dashboard-tile-outer-middle">
                <div className="dashboard-tile-left-chart">
                  <div className="coming-soon blink">
                    <h1>Analysing your channel...</h1>
                  </div>
                </div>
              </div>
              {/* <div className="dashboard-tile-outer ">
                <div className="dashboard-tile-rec-chart-bar  ">
                  <CustomLineChart />
                </div>
              </div> */}
              {/* <div className="dashboard-tile-outer-middle">
              <div className="dashboard-tile-left-chart">
                <div className="dashboard-content-chart">
                  <div className="dashboard-heading"></div>
                  <div className="dashboard-heading"></div>
                </div>

                <CustomConeBarChart />
              </div>
            </div>
           */}
            </div>
            <div className={`right-main-dashboard ${false ? "d-none" : ""}`}>
              {" "}
              <div className="dashboard-tile-outer-avatar">
                <div className="header-container">
                  <div className="header-content">
                    <div
                      className="icon-container"
                      onClick={() => {
                        openNotifModal();
                      }}
                    >
                      <div className="bell-icon">&#128276;</div>
                    </div>
                    <div
                      className="avatar-container"
                      onClick={() => {
                        openModal();
                      }}
                    >
                      <div className="avatar-ring">
                        <img
                          src={channelVerified ? channelThumbnailUrl : picture}
                          alt="Profile"
                          className="avatar-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="settings-popup"></div>
                </div>
              </div>
              <div className="dashboard-tile-outer Monetization-Tracker">
                <div className="dashboard-tile-square">
                  <div class="coming-soon-container-2">
                    <TileSquareComponent
                      subscribers={analyticsData?.getChannelSubscribers || "0"}
                      watchTime={analyticsData?.getTotalWatchTime || "0"}
                      title="Monetization Tracker"
                      loading={loading}
                    />{" "}
                  </div>
                </div>
              </div>{" "}
              <div className=" Subscribers-overview dashboard-tile-outer">
                <div className="dashboard-tile-square">
                  <div class="coming-soon-container-2">
                    <TileSquareComponent
                      title="Subscribers overview"
                      barchart={true}
                      subtitle="Current best times to post on Monday are 3pm - 6pm"
                      buttonTitle={
                        betadata?.keywordsBetaAccess
                          ? "Access pending"
                          : "Beta Access"
                      }
                      className={betadata?.keywordsBetaAccess ? "green" : ""}
                      disabled={betadata?.keywordsBetaAccess}
                      loading={betaLoading}
                      handleButtonClick={handleBetaAccess}
                      flag="keywordsBetaAccess"
                    />
                  </div>
                </div>
              </div>{" "}
              <div className="dashboard-tile-outer">
                <div className="dashboard-tile-square">
                  <TileSquareComponent
                    title="Subscribers Age Status"
                    CustomPieChart={true}
                  />
                </div>
              </div>{" "}
              {/* <div className="dashboard-tile-outer">
              <div className="dashboard-tile-square">
                <div class="coming-soon-container-2">
                  <TileSquareComponent
                    buttonTitle="Manage"
                    title="Top keyword opportunities"
                    subtitle="Select keywords in your niche to find the best keywords opportunities."
                  />{" "}
                </div>
              </div>
            </div>{" "} */}
              {/* <div className="dashboard-tile-outer">
              <div className="dashboard-tile-square">
                <div className="box-header"></div>{" "}
                <div class="coming-soon-container-2">
                  <TileSquareComponent
                    buttonTitle="Manage"
                    title="Top keyword opportunities"
                  />{" "}
                </div>
              </div>
            </div>{" "} */}
            </div>
          </div>
        </>
      )}
      <MyModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        modalTitle="Profile Details"
      >
        {profileDetails}
      </MyModal>
      <MyModal
        isOpen={modalIsOpenSub}
        closeModal={closeSubModal}
        modalTitle="Subscribe"
        color="none"
        removeHeader={true}
      >
        <SubscriptionModal removeSomeCss={true} />{" "}
      </MyModal>

      <MyModal
        isOpen={modalIsOpenNotif}
        closeModal={closeNotifModal}
        modalTitle="Notifications"
      >
        {notificationContent}
      </MyModal>
    </>
  );
};

export default Dashboard;

// useEffect(() => {
//   const disableRightClick = (e) => {
//     e.preventDefault();
//   };

//   const disableInspect = (e) => {
//     if (
//       e.keyCode === 123 ||
//       (e.ctrlKey && e.shiftKey && e.keyCode === 73) ||
//       (e.ctrlKey && e.shiftKey && e.keyCode === 67) ||
//       (e.ctrlKey && e.shiftKey && e.keyCode === 74) ||
//       (e.ctrlKey && e.keyCode === 85)
//     ) {
//       e.preventDefault();
//     }
//   };

//   window.addEventListener("contextmenu", disableRightClick);
//   document.onkeydown = disableInspect;

//   return () => {
//     window.removeEventListener("contextmenu", disableRightClick);
//     document.onkeydown = null;
//   };
// }, []);
