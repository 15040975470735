import React, { useState } from "react";

const ToggleButtons = ({ handleClick, button1Text, button2Text }) => {
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
    handleClick(buttonNumber);
  };

  return (
    <div className="btn-container">
      <button
        onClick={() => handleButtonClick(1)}
        className={`toggle-btn ${activeButton === 1 ? "active-btn" : ""}`}
      >
        {button1Text}
      </button>
      <button
        onClick={() => handleButtonClick(2)}
        className={`toggle-btn ${activeButton === 2 ? "active-btn" : ""}`}
      >
        {button2Text}
      </button>
    </div>
  );
};

export default ToggleButtons;
