import React from "react";

const ProgressBar = ({ count, loading, title }) => {
  return (
    count !== undefined && ( // Check if count is not undefined
      <div className={`progress-bar ${loading ? "blink access-filter" : ""}`}>
        <div className="progress-details">
          <div className="progress-value">
            <span style={{ fontWeight: "bold", color: "white" }}> {count}</span>
            <span>/50</span>
          </div>
          <div className="progress-label">{title}</div>
        </div>
        <div className="progress-container">
          <div
            className="progress-fill"
            style={{
              width: `${count ? (count / 50) * 100 : 0}%`,
            }}
          ></div>
        </div>
      </div>
    )
  );
};

export default ProgressBar;
