import React, { useState } from "react";
import "./TileSquareComponent.css";

const DailyVideoIdeas = ({
  videoTitles = [],
  title,
  buttonTitle,
  loading,
  handleButtonClick,
  channelVerified,
}) => {
  console.log("videoTitles", videoTitles);
  const [copiedIndex, setCopiedIndex] = useState(null);

  const handleCopyToClipboard = (title, index) => {
    navigator.clipboard.writeText(title);
    setCopiedIndex(index);

    // Reset copied index after 3 seconds
    setTimeout(() => {
      setCopiedIndex(null);
    }, 3000);
  };

  return (
    <div>
      <div className="box-header">
        {title && <h3 className="title-head">{title}</h3>}
        {buttonTitle && (
          <button onClick={handleButtonClick}>{buttonTitle}</button>
        )}
      </div>
      <div className="horizontalLine"></div>
      {videoTitles.map((title, index) => (
        // <div className="daily-video-title-box" key={index}>
        <div
          className={`daily-video-title-box ${
            loading ? "blink access-filter" : ""
          } `}
          key={index}
        >
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontFamily: "Arial, Helvetica, sans-serif;",
              color: "rgb(198 198 198)",
              fontSize: "14px",
            }}
          >
            {title}
          </div>
          <button
            style={{ marginLeft: "10px" }}
            onClick={() => handleCopyToClipboard(title, index)}
          >
            {copiedIndex === index ? "Copied!" : "Copy"}
          </button>
        </div>
      ))}{" "}
      {videoTitles.length === 0 && (
        <div className={`coming-soon ${channelVerified ? "blink" : ""}`}>
          <h1> {channelVerified ? "Analysing..." : "Connect Channel"}</h1>
        </div>
      )}
    </div>
  );
};

export default DailyVideoIdeas;
