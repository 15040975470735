import React, { PureComponent } from "react";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "18-24",
    uv: 31.47,
    pv: 2400,
    fill: "#6a3a4f",
  },
  {
    name: "25-29",
    uv: 26.69,
    pv: 4567,
    fill: "#5d5b96",
  },
  {
    name: "30-34",
    uv: 15.69,
    pv: 1398,
    fill: "#505082",
  },
  {
    name: "35-39",
    uv: 8.22,
    pv: 9800,
    fill: "#4b3855",
  },
];

const style = {
  top: "50%",
  right: "-30px",
  // left: "15px",
  transform: "translate(0, -50%)",
  lineHeight: "24px",
};

export default class CustomShapePieChart extends PureComponent {
  static demoUrl = "https://codesandbox.io/s/simple-radial-bar-chart-qf8fz";

  render() {
    return (
      <div style={{ height: "200px", margin: "0px 30px 0px auto" }}>
        <ResponsiveContainer width="100%" height="100%">
          <RadialBarChart
            cx="45%" // Adjust the position of the circle to the left
            cy="50%"
            innerRadius="10%"
            outerRadius="80%"
            barSize={10}
            data={data}
          >
            <RadialBar
              minAngle={15}
              // label={{ position: "insideStart", fill: "#fff" }}
              background={{ fill: "#161a22" }} // Change the background color to grey
              clockWise
              dataKey="uv"
            />
            <Legend
              iconSize={10}
              layout="vertical"
              verticalAlign="middle"
              wrapperStyle={style}
            />
          </RadialBarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
