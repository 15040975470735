import React, { useState, useEffect } from "react";
import axios from "axios";
import "./redeemtions.css";
import { useNavigate } from "react-router-dom";
import useNotification from "../hooks/usenotification";
import { baseUrl } from "../utils/config";

const Redeemtions = ({ token }) => {
  console.log("token3", token);
  const [message, setMessage] = useState("");
  const { NotificationComponent, triggerNotification } =
    useNotification("top-right");
  const navigate = useNavigate(); // Use the useNavigate hook for navigation

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(`${baseUrl}/api/redeem`, {
        params: {
          token: token,
        },
      });
      setMessage(response.data);
      triggerNotification({
        type: "success",
        message: " You've successfully redeemed your subscription! 🎉",
        duration: 3000,
        animation: "pop",
      });
      // Redirect to dashboard after 3 seconds on success
      setTimeout(() => {
        navigate("/dashboard");
        window.location.reload(); // Refresh the page after navigation
      }, 3000); // 3000 milliseconds = 3 seconds
    } catch (error) {
      setMessage("Error sending redemption link");
    }
  };
  console.log("nothing");

  const features = [
    "Premium Support When You Need It ",
    "Unleash Your Creative with all Tools",
    "Unlimited Video Titles",
    "Unlimited Video Descriptions",
    "Unlimited Video Tags",
    "Access to all tools and features",
    // "Dive into a World of Endless Possibilities",
    "Coming Soon: Stunning Thumbnails generation at Your Fingertips",
  ];

  return (
    <div className="subscription-modal-overlay">
      {NotificationComponent}

      <div className="subscription-modal-glossy-overlay">
        <h2 className="subscribe-heading-text">
          Unlock Premium Features - Redeem Now!
        </h2>
        <div className="subscription-modal">
          <div className="features-list">
            {features.map((feature, index) => (
              <div className="feature-item" key={index}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="tick-green-svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="badge">{feature}</span>
              </div>
            ))}
          </div>
          <div className="subscription-details">
            <div className="price-container">
              <h2 className="price-heading">$0</h2>
              <small className="text-price"> / month</small>
            </div>

            <div className="subscribe-button">
              <button onClick={handleSubmit}>
                Redeem
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="arrow-right-svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <small className="text-center text-xs">No payment needed</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Redeemtions;
