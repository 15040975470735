import React from "react";
import "./PrivacyPolicy.css"; // Import your custom CSS file

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className="privacy-policy-container">
        <h1>Privacy Policy for TubeInsights</h1>

        <p className="date">Effective Date: 01-01-2024</p>

        <div className="content">
          <p>
            Thank you for using TubeInsights! This Privacy Policy outlines how
            we collect, use, and share information when you use our website and
            services. By using TubeInsights, you agree to the terms of this
            Privacy Policy.
          </p>

          <h2>1. Information We Collect About You</h2>
          <p>
            We collect information you provide when you create an account on our
            website, including your email address. Additionally, we gather data
            about your usage of our services, such as the YouTube channels you
            connect to the service and demographic information.
          </p>

          <h2>2. How We Use Your Information</h2>
          <p>
            we are committed to protecting the privacy of our users. We use the
            collected information to provide, maintain, and improve our
            services. This includes:
          </p>
          <ul>
            <li>Generating tags, titles, and descriptions using AI models.</li>
            <li>
              Showcasing private YouTube channel analytics on our website with
              custom graphs.
            </li>
            <li>
              Communicating with you and enhancing our understanding of user
              demographics.
            </li>
            <li>
              Your private information which is private will not be shared with
              anyone.
            </li>
          </ul>

          <h2>3. How We Share Your Information</h2>
          <p>
            we are committed to protecting the privacy of our users. We may
            share your information with third-party service providers to
            provide, maintain, and improve our services. For example:
          </p>
          <ul>
            <li>Stripe for processing payments.</li>
            <li>
              OpenAI's ChatGPT model for generating tags, titles, descriptions,
              and summaries from video transcriptions.
            </li>
          </ul>
          <p>
            We ensure that these third parties are compliant with data
            protection regulations and have measures in place to safeguard your
            information. Note that YouTube channel stats and metrics are not
            shared with any third-party tools or platforms; only public data is
            shared for AI-based suggestions.
          </p>

          <h2>4. Data Retention and Deletion</h2>
          <p>
            We retain your information for as long as your account is active or
            as needed to provide you with our services. If you wish to delete
            your data, you may send a request to{" "}
            <a href="mailto:contact@tubeinsights.pro">
              contact@tubeinsights.pro
            </a>
            , and we will process your request in accordance with applicable
            laws.
          </p>

          <h2>5. Consent to Share Data with AI Models</h2>
          <p>
            Before sharing your information, including video analytics data,
            with AI models like ChatGPT to generate tags, titles, descriptions,
            and summaries, we obtain your explicit consent. During account setup
            or usage, you will be prompted to provide consent for this specific
            data sharing purpose through a clear opt-in checkbox and detailed
            explanation.
          </p>

          <h2>6. Compliance with Google API Services User Data Policy</h2>
          <p>
            TubeInsights' use and transfer of information received from Google
            APIs to any other app will adhere to Google API Services User Data
            Policy, including the Limited Use requirements. For more
            information, refer to the{" "}
            <a href="https://developers.google.com/terms/api-services-user-data-policy">
              Google API Services User Data Policy
            </a>
            .
          </p>

          <h2>7. Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy or our practices
            related to your information, please contact us at{" "}
            <a href="mailto:contact@tubeinsights.pro">
              contact@tubeinsights.pro
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
