import React, { PureComponent } from "react";
import { LineChart, Line, ResponsiveContainer } from "recharts";

class CustomLineChart extends PureComponent {
  render() {
    const { viewsanalyticsData } = this.props;
    const lastSevenDaysData = viewsanalyticsData?.slice(-7);
    console.log("lastSevenDaysData", lastSevenDaysData, viewsanalyticsData);

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={300} height={100} data={lastSevenDaysData}>
          <Line
            type="monotone"
            dataKey="Views"
            stroke="#8884d8"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default CustomLineChart;
