import React from "react";
import { Link } from "react-router-dom";
import "./YoutubeCard.css"; // Import CSS for styling

const UniqueCard = ({ videoData, loading = true, skeletoncount }) => {
  const skeleton = (
    <div className={`unique-card ${loading ? "loading" : ""}`}>
      <div className="unique-skeleton-img"></div>
      <div className="unique-card-info unique-skeleton-info">
        <div className="unique-skeleton-title"></div>
        <div className="unique-skeleton-description"></div>
      </div>
    </div>
  );

  const content = (video) => (
    <Link to={`details/${video.videoId}`} style={{ textDecoration: "none" }}>
      {/* <Link style={{ textDecoration: "none" }}> */}
      <div className="unique-card">
        <div className="unique-overlay"></div>
        <img src={video.thumbnailUrl} alt={video.title} />
        {/* <div className="unique-card-info-2">
          <p className="unique-title-top">{video.title}</p>
        </div> */}
        <div className="unique-card-info">
          <p className="unique-title">{video.title}</p>
        </div>
      </div>
    </Link>
  );

  if (loading) {
    const skeletons = Array.from({ length: skeletoncount })?.map((_, index) => (
      <div
        className={`unique-card ${loading ? "loading" : ""}`}
        key={`unique-skeleton-${index}`}
      >
        <div className="unique-skeleton-img"></div>
        <div className="unique-card-info unique-skeleton-info">
          <div className="unique-skeleton-title"></div>
          <div className="unique-skeleton-description"></div>
        </div>
      </div>
    ));
    return skeletons;
  }

  return videoData?.map((video) => content(video));
};

export default UniqueCard;
