import React from "react";
import "./styles.css";

const LoadingComponent = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="loading-container">
      <div className="bubble bubble1"></div>
      <div className="bubble bubble2"></div>
      <div className="bubble bubble3"></div>
    </div>
  );
};

export default LoadingComponent;
