import React, { useState } from "react";

const CardBalance = ({ balance, code }) => {
  return (
    <div className="card-balance">
      <div className="card-balance-inner">
        <span className="card-balance-value">{balance}</span>
        <span className="card-balance-label">My Cards Balance</span>
      </div>
      <div className="card-balance-code">{code}</div>
    </div>
  );
};

const cardBalanceStyles = `
.card-balance {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial, sans-serif;
}

.card-balance-inner {
  display: flex;
  flex-direction: column;
}

.card-balance-value {
  font-size: 2rem;
  font-weight: bold;
}

.card-balance-label {
  font-size: 0.8rem;
  color: #666;
}

.card-balance-code {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  background-color: #eee;
  padding: 5px;
  border-radius: 5px;
}
`;

const App = () => {
  return (
    <div>
      <style>{cardBalanceStyles}</style>
      <CardBalance balance="$13.564" code="2376" />
      <CardBalance balance="$123.45" code="1111" />
    </div>
  );
};

export default App;
