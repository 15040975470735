import { useCallback } from "react";

const useApi = () => {
  const callApi = useCallback(
    async ({ url, method = "GET", payload = null, headers = {}, token }) => {
      try {
        if (token) {
          headers["Authorization"] = `Bearer ${token}`;
        }

        const response = await fetch(url, {
          method,
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          body: payload ? JSON.stringify(payload) : null,
        });

        console.log("response", response);

        // Check if response is not okay
        if (!response.ok) {
          const result = await response.json();
          throw new Error(result.message || "Something went wrong");
        }

        // Parse JSON response and return data
        const responseData = await response.json();
        console.log("responseData", responseData);
        return responseData; // Return parsed JSON data on success
      } catch (err) {
        throw err; // Throw error on failure
      }
    },
    []
  );

  return { callApi };
};

export default useApi;
