import React, { useEffect, useState } from "react";
import "./DetailOptimizePage.css";
import Button from "../common/Button";
import MyModal from "../common/MyModal";

const DetailOptimizePage = () => {
  const [modalIsOpenNotif, setModalIsOpenNotif] = useState(true);

  const closeSubModal = () => setModalIsOpenNotif(false);

  return (
    <div className="detailed-analytics-page access-filter">
      <MyModal
        isOpen={modalIsOpenNotif}
        closeModal={closeSubModal}
        modalTitle="Upgrade to premium"
      >
        {" "}
        <p>To use this feature, you need premium access.</p>
      </MyModal>
      {/* Top bar with buttons */}
      <div className="left-part">
        {/* <div className="small-rec-cards-section">
          <div className="small-rec-cards b4a194"></div>
          <div className="small-rec-cards d2f9e8"></div>
          <div className="small-rec-cards bab6c1"></div>
          <div className="small-rec-cards ea2"></div>
        </div> */}
        <div class="title-row gre">
          <div class="title-row-content">Overview</div>
          <div class="number">123</div>
        </div>
        {/* Divided section with 3 cards */}
        <div className="divided-section">
          <div className="card b9c1b6 w-30">
            <div class="card-header-dots">
              <h2>Summary</h2>
              <div class="three-dots  d-none">...</div>
            </div>
            <div class="card-content">
              <div class="row d2e1f9">
                <div class="word">Overview</div>
                <div class="number">123</div>
              </div>
              <div class="row d2e1f9">
                <div class="word">Campaigns</div>
                <div class="number">456</div>
              </div>
              <div class="row d2f9e8">
                <div class="word">Ad Group</div>
                <div class="number">789</div>
              </div>
              <div class="row f7f9d2">
                <div class="word">Keywords</div>
                <div class="number">012</div>
              </div>
            </div>
          </div>
          <div className="card card-large bfb293 w-75"></div>
        </div>
        <div class="title-row ff7eae">
          <div class="title-row-content">Prev Title :</div>
          <div class="title-row-content"></div>
          <Button title="Use" />
        </div>
        <div class="title-row d2e1f9">
          <div class="title-row-content">New Title :</div>
          <div class="title-row-content"> </div>

          <Button title="Use" />
        </div>
        <div className=" card-large-des bfb293">
          {" "}
          <div class="title-row-content"></div>
        </div>
        <div className=" card-large-des f9e8c">
          <div class="title-row-content">
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
            How to become an expert in How to become an expert in How to become
            an expert in How to become an expert in How to become an expert in
          </div>{" "}
        </div>
        <div className="divided-section-tags">
          <div className=" card-large-tags ffb1b1"></div>
          <div className=" card-large-tags f9e8c"></div>
        </div>
      </div>
    </div>
  );
};

export default DetailOptimizePage;
