import React from "react";

const calculateRotation = (progress) => `rotate(${progress}deg)`;

const RadialProgressBar = (props) => {
  const { totalIncome, totalExpense, totalBonus } = props;

  const progressBarStyle = {
    position: "relative",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    overflow: "hidden",
    border: "5px solid #3498db",
  };

  const progressBarBeforeStyle = {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    transformOrigin: "50% 50%",
    transform: calculateRotation(totalIncome),
  };

  const labelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "left",
    color: "#333",
    fontSize: "14px",
    fontWeight: "bold",
    padding: "0 10px",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ display: "flex", gap: "1rem" }}>
        <div style={progressBarStyle}>
          <div style={progressBarBeforeStyle}></div>
          <div style={labelStyle}>
            <span>Total Income:</span> ${totalIncome}
          </div>
        </div>
        <div style={progressBarStyle}>
          <div
            style={{
              ...progressBarBeforeStyle,
              transform: calculateRotation(totalExpense),
            }}
          ></div>
          <div style={labelStyle}>
            <span>Total Expense:</span> ${totalExpense}
          </div>
        </div>
        <div style={progressBarStyle}>
          <div
            style={{
              ...progressBarBeforeStyle,
              transform: calculateRotation(totalBonus),
            }}
          ></div>
          <div style={labelStyle}>
            <span>Total Bonus:</span> ${totalBonus}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadialProgressBar;
