import React from "react";

const Button = ({
  className,
  onClick,
  disabled,
  title,
  flag,
  loading,
  btncolor,
  handleClick,
}) => {
  const handleButtonClick = (flag) => {
    handleClick(flag);
  };

  return (
    <button
      className={`button ${className}  ${
        loading ? "blink access-filter" : ""
      } ${disabled ? "not-allowed" : ""} `}
      onClick={() => handleButtonClick(flag)}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default Button;
