/*global chrome*/

import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie"; // You may need to install this library
import { jwtDecode } from "jwt-decode";
import { baseextensionId } from "./utils/config";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => Cookies.get("token") || null);
  const [user, setUser] = useState(null); // Add user state
  const login = (newToken) => {
    setToken(newToken);
    Cookies.set("token", newToken, { expires: 7 }); // Set cookie with a 7-day expiration, adjust as needed  navigate("/dashboard");
  };

  const loginWithGoogle = async () => {
    try {
      // Call the Google Sign-In API
      const googleUser = await window.gapi.auth2.getAuthInstance().signIn();

      // Extract user information from the Google user object
      const profile = googleUser.getBasicProfile();
      const userData = {
        id: profile.getId(),
        name: profile.getName(),
        email: profile.getEmail(),
        imageUrl: profile.getImageUrl(),
      };

      // Set the user state with the extracted data
      setUser(userData);

      // You may also want to set the token here if applicable
      // setToken(yourToken);
    } catch (error) {
      if (error.error === "popup_closed_by_user") {
        console.warn("Google Sign-In popup closed by user");
      } else {
        console.error("Google Sign-In failed:", error);
        throw error; // Rethrow the error to handle it in the component
      }
    }
  };

  const verifyGoogleToken = async (googleToken) => {
    try {
      const response = await fetch(
        "https://app.tubeinsights.pro/verifyGoogleToken",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ googleToken }),
        }
      );

      if (!response.ok) {
        throw new Error("Google token verification failed");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error verifying Google token:", error.message);
      throw new Error("Google token verification failed");
    }
  };

  const logout = () => {
    setToken(null);
    Cookies.remove("token");
    // const extensionId = "ecnepininffodhhimnlocaehgpmcnnlk";
    const extensionId = baseextensionId;
    // const extensionId = "ikheknefdppkmbagcblipglpalohcfjm";

    chrome.runtime.sendMessage(
      extensionId,
      {
        accessToken: "",
        email: user?.email,
        userId: user?._id,
        type: "loggedInUser",
      },
      function (response) {
        if (chrome.runtime.lastError) {
          console.error(chrome.runtime.lastError);
        } else {
          console.log("Received response from extension:", response);
        }
      }
    );
  };

  // // Cleanup function to remove the cookie when the component unmounts
  // useEffect(() => {
  //   return () => {
  //     Cookies.remove("token");
  //   };
  // }, []);

  return (
    <AuthContext.Provider
      value={{ token, login, user, logout, loginWithGoogle, verifyGoogleToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
