import React, { useState } from "react";
import Notes from "./notes";

const colors = [
  "#D1C4E9", // deep purple
  "#FFCDD2", // red
  "#F8BBD0", // pink
  "#E1BEE7", // purple
  "#C5CAE9", // indigo
  "#BBDEFB", // blue
  "#C8E6C9", // green
];

function NotepadMain() {
  const [notes, setNotes] = useState([
    {
      id: 1,
      text: "TubeInsights - Elevate Your YouTube Presence with the power of Ai",
      color: "#D1C4E9",
    },
    {
      id: 2,
      text: "TubeInsights - Elevate Your YouTube Presence with the power of Ai",
      color: "#FFCDD2",
    },
    {
      id: 3,
      text: "TubeInsights - Elevate Your YouTube Presence with the power of Ai",
      color: "#F8BBD0",
    },
    {
      id: 5,
      text: "TubeInsights - Elevate Your YouTube Presence with the power of Ai",
      color: "#E1BEE7",
    },
  ]);

  const [note, setNote] = useState("");
  const [selectedColor, setSelectedColor] = useState(colors[0]);

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <textarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Type your note here..."
          style={{
            width: "300px",
            padding: "10px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            resize: "none",
            fontSize: "14px",
          }}
        />
        <div style={{ display: "flex", gap: "5px" }}>
          {colors.map((color) => (
            <div
              key={color}
              onClick={() => setSelectedColor(color)}
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: color,
                cursor: "pointer",
                border: selectedColor === color ? "2px solid black" : "none",
              }}
            />
          ))}
        </div>
        <button
          onClick={() => {
            setNotes([
              ...notes,
              { id: notes.length + 1, text: note, color: selectedColor },
            ]);
            setNote("");
            setSelectedColor(colors[0]);
          }}
          style={{
            padding: "10px 20px",
            borderRadius: "8px",
            border: "none",
            backgroundColor: "#4CAF50",
            color: "white",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          Add Note
        </button>
      </div>
      <Notes notes={notes} setNotes={setNotes} />
    </div>
  );
}

export default NotepadMain;
