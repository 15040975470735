import useApi from "../hooks/useApi";
import { baseUrl } from "../utils/config";
import { useAuth } from "../AuthContext";

const useApiService = () => {
  const { callApi } = useApi();
  const { token } = useAuth();

  const FetchUserData = async (payload) => {
    const url = `${baseUrl}/fetch-user-data`;
    const method = "POST";
    try {
      const response = await callApi({ url, method, payload, token });
      return response;
    } catch (error) {
      throw error; // Rethrow error for handling in the component
    }
  };

  const generateScript = async (payload) => {
    const url = `${baseUrl}/generate-script`;
    const method = "POST";
    try {
      const response = await callApi({ url, method, payload, token });
      return response;
    } catch (error) {
      throw error; // Rethrow error for handling in the component
    }
  };

  const TrackMonitization = async (payload) => {
    const url = `${baseUrl}/monitizationtracker`;
    const method = "POST";
    try {
      const response = await callApi({ url, method, payload, token });
      return response;
    } catch (error) {
      throw error; // Rethrow error for handling in the component
    }
  };

  const userInfoData = async (payload) => {
    const url = `${baseUrl}/userinfo`;
    const method = "GET";

    try {
      const response = await callApi({ url, method, payload, token });
      console.log("respons5", response);
      return response;
    } catch (error) {
      throw error; // Rethrow error for handling in the component
    }
  };

  // Add more API functions as needed

  return {
    FetchUserData,
    userInfoData,
    TrackMonitization,
    generateScript,
  };
};

export default useApiService;
