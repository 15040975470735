// Get the current protocol, host, and port
const protocol = window.location.protocol;
const host =
  window.location.hostname === "localhost"
    ? "localhost"
    : "api.tubeinsights.pro";
let port = window.location.port;

// Check if the current port is 3000, then change it to 3001
if (port === "3000") {
  port = "3001";
}

// Construct the base domain with the updated port
const baseDomain = `${protocol}//${host}:${port}`;
const baseUrl = `${baseDomain}`; // this is the baseurl for API calls only
// const baseUrl = "https://api.tubeinsights.pro";

const ProdextensionId = "ecnepininffodhhimnlocaehgpmcnnlk";
const localextensionId = "ikheknefdppkmbagcblipglpalohcfjm";
let baseextensionId = "";

if (port === "3001") {
  baseextensionId = localextensionId;
} else {
  baseextensionId = ProdextensionId;
}

export { baseUrl, baseextensionId };
