// Contact.js
import React from "react";
import "./Contact.css";
import Cookies from "js-cookie"; // You may need to install this library

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact-box">
        <h2 className="contact-header">Contact</h2>
        <p className="contact-subheader">
          For anything (e.g. refunds, queries, requests) reach out either of the
          following options:
        </p>
        <div className="contact-buttons">
          <a href="mailto:support@tubeinsights.pro" className="mail-button">
            Mail Us
          </a>

          <a
            href="https://twitter.com/tubeinsights"
            target="_blank"
            class="social-button"
          >
            Visit our Facebook Page
          </a>

          <a
            href="https://twitter.com/tubeinsights"
            target="_blank"
            class="social-button"
          >
            Follow us on Twitter
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
