// api.js
import Cookies from "js-cookie"; // You may need to install this library
import axios from "axios";
import { baseUrl } from "../utils/config";
const token = Cookies.get("token") || null;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : "",
  },
});

export async function callApi(method, endpoint, payload = null) {
  try {
    const response = await axiosInstance({
      method,
      url: endpoint,
      data: payload,
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("API request error:", error.response.data);
      console.error("Status code:", error.response.status);

      // Handle different error status codes here
      if (error.response.status === 401) {
        // Unauthorized - token might be expired or invalid
        // Redirect to login page or handle token refresh
      } else if (error.response.status === 404) {
        // Resource not found
        // Handle accordingly
      } else {
        // Other error status codes
        // Handle or display a generic error message
      }

      throw error.response.data; // You might want to throw the error response for further handling
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error("Error setting up the request:", error.message);
    }

    throw error; // Re-throw the error for further handling if needed
  }
}
