// AccountSettings.js
import React, { useState } from "react";
import axios from "axios";
import "./AccountSettings.css"; // You can define your styles in AccountSettings.css
import { baseUrl } from "../../utils/config";

const AccountSettings = ({ userInfo }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${baseUrl}/api/redeem`, {
        email,
      });
      setMessage(response.data);
    } catch (error) {
      setMessage("Error sending redemption link");
    }
  };
  return (
    <div className="account-settings-container">
      <div className="settings-box">
        <div className="settings-header">
          <h2 className="settings-header-h">Settings</h2>
          {/* <p>Manage your account</p> */}
        </div>

        <div className="input-group">
          <label htmlFor="name">Your Name</label>
          <input
            className="not-allowed"
            disabled
            type="text"
            id="name"
            placeholder={userInfo?.name}
          />
        </div>

        <div className="input-group">
          <label htmlFor="email">Email Address</label>
          <input
            className="not-allowed"
            type="text"
            id="email"
            placeholder={userInfo?.email}
            disabled
          />
          <p className="disabled-text">
            You cannot change your Name and email for now, create a new account
            if you wish to.
          </p>
        </div>
        {userInfo?.canRefer && (
          <>
            <div className="input-group">
              <label htmlFor="email">Gift Premium</label>
              <input
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <p className="disabled-text">
                Give 3 months of premium access to your friends and family!
                Simply enter their email to send them the gift of premium
                features.
              </p>
            </div>

            <div className="save-button-css">
              <button className="save-button" onClick={handleSubmit}>
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountSettings;
