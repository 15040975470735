/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { forwardRef } from "react";

const Note = forwardRef(
  ({ content, initialPos, idx, color, onDelete, ...props }, ref) => {
    return (
      <div
        ref={ref}
        style={{
          position: "absolute",
          left: `${initialPos?.x}px`,
          top: `${initialPos?.y}px`,
          border: "2px solid black",
          borderRadius: "8px",
          userSelect: "none",
          padding: "10px",
          width: "220px",
          cursor: "move",
          backgroundColor: color,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          fontFamily: "Arial, sans-serif",
          fontSize: "16px",
          color: "#333",
          minHeight: "100px",
        }}
        {...props}
      >
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "5px",
          }}
        >
          <button
            onClick={onDelete}
            style={{
              height: "2px",
              color: "white",
              border: "none",
              cursor: "pointer",
              width: "1px",
              justifyContent: "center",
            }}
          >
            ✖
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontFamily: "serif",
              fontSize: "medium",
            }}
          >
            {" "}
            {content}
          </span>
        </div>
      </div>
    );
  }
);

export default Note;
