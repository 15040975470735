import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import useApiService from "../actions/useApiService";
import LoadingComponent from "./EmailInbox/LoadingComponent ";

const GoogleLoginComponent = ({ onLogin }) => {
  const { FetchUserData } = useApiService();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const googleLogin = useGoogleLogin({
    scope: "openid profile email",
    accessType: "offline",

    onSuccess: async (codeResponse) => {
      console.log("Google Code Response:", codeResponse);
      setIsLoading(true);
      const access_token = codeResponse?.access_token;

      if (!access_token) {
        console.error("Access token not found in the response.");
        return;
      }

      try {
        const response = await FetchUserData({ access_token });

        try {
          console.log("response:", response);
          login(response?.accessToken);
          navigate("/dashboard");
          window.location.reload();
        } catch (error) {
          console.error("Login failed:", error.message);
        }
      } catch (error) {
        console.error("Error exchanging access token for user data:", error);
      }
    },
    onError: (errorResponse) =>
      console.error("Google Login Error:", errorResponse),
  });

  return (
    <div className="login-container">
      {isLoading ? (
        <LoadingComponent isLoading={isLoading} />
      ) : (
        <>
          <div className="login-g-button">
            <button
              className="login-google"
              style={{
                padding: "8px 15px",
                cursor: isConsentGiven ? "pointer" : "no-drop",
                border: "none",
                fontSize: "16px",
                width: "100%",
                backgroundColor: isConsentGiven ? "#4285F4" : "#d3d3d3",
                color: "#fff",
              }}
              onClick={googleLogin}
              disabled={!isConsentGiven}
            >
              <svg
                style={{ marginRight: "10px" }}
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 48 48"
                enableBackground="new 0 0 48 48"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#FFC107"
                  d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
              c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4
              C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                ></path>
                <path
                  fill="#FF3D00"
                  d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
              C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                ></path>
                <path
                  fill="#4CAF50"
                  d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
              c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                ></path>
                <path
                  fill="#1976D2"
                  d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
              c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                ></path>
              </svg>
              Sign In with Google
            </button>
          </div>
          <div className="consent-checkbox d-flex mt-2">
            <input
              style={{ width: "2rem" }}
              type="checkbox"
              id="consent"
              checked={isConsentGiven}
              onChange={(e) => setIsConsentGiven(e.target.checked)}
            />
            <label htmlFor="consent">
              I have read and agree to the{" "}
              <a href="/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default GoogleLoginComponent;
