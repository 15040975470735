import React from "react";
import "./PrivacyPolicy.css"; // Import your custom CSS file

class TermsOfService extends React.Component {
  render() {
    return (
      <div className="terms-of-service-container">
        <h1>Terms of Service</h1>

        <p>
          These Terms of Service (the “Terms”) are a legal agreement between you
          and tubeinsights, and they govern your use of the tubeinsights.pro
          website and services (collectively, the “Service”). By accessing or
          using the Service, you agree to be bound by these Terms and by the
          tubeinsights.pro Privacy Policy, which is incorporated into these
          Terms by reference. If you do not agree to these Terms, you may not
          use the Service.
        </p>

        <h2>1. Accounts</h2>

        <p>
          To access certain features of the Service, you must create an account.
          You agree to provide accurate and complete information when creating
          your account, and you agree to keep your account information
          up-to-date. You are responsible for all activities that occur under
          your account, including any unauthorized activities. If you suspect
          that someone has gained unauthorized access to your account, you
          should notify tubeinsights.pro immediately.
        </p>

        <h2>2. Refunds</h2>

        <p>Refunds for the Service will be given within 7 days of purchase.</p>

        <h2>3. No Transfer of Accounts</h2>

        <p>
          tubeinsights.pro does not allow the transfer of accounts to another
          party.
        </p>

        <h2>4. Commercial Use</h2>

        <p>Commercial use of the Service is allowed.</p>

        <h2>5. Use of Company Logos and Names</h2>

        <p>
          By signing up for the Service with a work email address, you grant
          tubeinsights.pro permission to use your company's logo and name on our
          landing page or in our advertisements to indicate that your company
          uses our Service. This permission is conditional upon your continued
          use of the Service.
        </p>

        <h2>6. Disclaimer of Warranty and Limitation of Liability</h2>

        <p>
          The Service is provided on an “as is” and “as available” basis.
          tubeinsights.pro does not warrant that the Service will be error-free,
          uninterrupted, or secure, and you use the Service at your own risk.
          tubeinsights.pro is not responsible for any legal mishappenings
          generated by AI.
        </p>

        <h2>7. Changes to these Terms of Service</h2>

        <p>
          tubeinsights.pro may modify these Terms at any time by posting a
          revised version on the Service. You agree that your continued use of
          the Service after the effective date of the revised Terms constitutes
          your acceptance of the revised Terms.
        </p>

        <h2>8. Contact Us</h2>

        <p>
          If you have any questions about these Terms or the Service, please
          contact us at{" "}
          <a href="mailto:contact@tubeinsights.pro">contact@tubeinsights.pro</a>
          .
        </p>
      </div>
    );
  }
}

export default TermsOfService;
