import React, { useState } from "react";
import "./DetailedAnalyticsPage.css"; // Import your CSS file for styling
import useApiService from "../actions/useApiService";
import useNotification from "../hooks/usenotification";
import Button from "./common/Button";
import aitechnology from "../assets/aitechnology.png";

const DetailedAnalyticsPage = () => {
  const { NotificationComponent, triggerNotification } =
    useNotification("top-right");
  const [title, setTitle] = useState("");
  const [showGeneratefield, showShowGeneratefield] = useState(true);
  const [scriptData, setScriptData] = useState({});
  const [loading, setLoading] = useState(false);

  const { generateScript } = useApiService();

  const handleGenerate = async () => {
    try {
      // Add your generate logic here
      console.log("Generate video idea for:", title);
      setLoading(true);
      showShowGeneratefield(false);

      // Assuming FetchUserData is a function that returns a promise
      const response = await generateScript({
        title,
        language: "en",
        videoType: "short",
      });

      if (response?.content) {
        setScriptData(response?.content);
        setLoading(false);
      }

      // Handle the response as needed
      console.log("Response:", response);
    } catch (error) {
      // Handle any errors that occur during the fetch
      console.error("Error generating video idea:", error);
    }
  };

  const scriptContent = `
  Start by introducing yourself and the video topic. 
  Explain why it's important and what viewers will gain from it. 
  Share personal anecdotes or insights to make the introduction engaging. 
  Keep it concise and to the point, but make sure to capture the viewer's attention.
  
  As you progress through the script, dive into the main points you want to cover. 
  Break down complex ideas into simpler segments and use examples to clarify your points. 
  Maintain a conversational tone and encourage viewers to follow along with the content. 
  
  Conclude with a strong ending that reinforces the main message of the video. 
  Include a call-to-action that prompts viewers to like, comment, and subscribe. 
  Express gratitude and invite them to watch more of your content for additional value.
  
  Remember, the key to a successful script is clarity, engagement, and relevance. 
  Always keep your audience in mind and tailor your script to meet their needs and interests.
`;

  const segments = scriptData?.outlines
    ? Object.keys(scriptData?.outlines).map((key) => {
        const segment = scriptData?.outlines[key];
        return {
          title: key,
          duration: segment?.time || "",
          content: segment?.content || "",
        };
      })
    : [];

  const getNumberColor = (number) => {
    if (number > 70) return "green";
    if (number < 50) return "red";
    return "orange";
  };
  return (
    <>
      {NotificationComponent}
      {showGeneratefield ? (
        <div
          className="d-flex "
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div className="gnrtvids">
            <div className="generate-content-center aic">
              <div
                className="h34bc52c h34bc52e h34bc52t h34bc52x h34bc57y"
                style={{
                  width: "100px",
                  height: "100px",
                  marginBottom: "1rem",
                }}
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="h0f1ft22 h0f1ft2a h0f1ft2c"
                >
                  <path d="M12 3.2a8.793 8.793 0 0 0-7.48 4.163 1.2 1.2 0 1 1-2.04-1.267A11.193 11.193 0 0 1 12 .8C18.186.8 23.2 5.814 23.2 12c0 6.186-5.014 11.2-11.2 11.2-5.686 0-10.38-4.235-11.103-9.724a1.2 1.2 0 0 1 2.379-.314A8.8 8.8 0 1 0 12 3.2z"></path>
                  <path d="M7 8a1 1 0 0 0-1-1H3V4a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1zm2.91 1.227c.406.103.637.468.72 1.141.044.346.095.526.216.768.194.386.487.679.87.87.119.059.218.098.692.27.453.165.686.317.824.536a.8.8 0 0 1 .076.586c-.1.339-.337.518-.992.752a4.95 4.95 0 0 0-.62.248c-.48.24-.848.695-.994 1.228-.02.074-.049.23-.064.344-.049.368-.095.548-.194.747-.185.374-.551.548-.973.461a.747.747 0 0 1-.377-.19c-.214-.201-.324-.476-.388-.97-.06-.463-.172-.756-.408-1.069a2.31 2.31 0 0 0-.47-.444c-.181-.121-.287-.167-.92-.396a2.545 2.545 0 0 1-.587-.29.769.769 0 0 1-.3-.801c.06-.255.256-.46.589-.614.071-.033.291-.118.49-.19.517-.187.688-.274.918-.47a1.98 1.98 0 0 0 .583-.874c.05-.153.077-.284.113-.551.071-.544.256-.89.55-1.035a1.04 1.04 0 0 1 .17-.063c.126-.03.346-.028.477.006zm4.585.984c.122.369.19.789.705.789.516 0 .583-.42.705-.789C16.142 9.5 17.2 9.821 17.2 9c0-.821-1.058-.5-1.295-1.211C15.783 7.42 15.715 7 15.2 7c-.516 0-.583.42-.705.789C14.258 8.5 13.2 8.179 13.2 9c0 .821 1.058.5 1.295 1.211z"></path>
                </svg> */}
                <img
                  src={aitechnology}
                  alt="Generate Video Ideas"
                  className="h0f1ft22 h0f1ft2a h0f1ft2c"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <div className="generate-content-center">
                <p className="_196jrpp0 _196jrpp2b _196jrpp2f _196jrpp1f _196jrpp2h">
                  Generate Video Ideas
                </p>
                <p className="_196jrpp0 _196jrpp28 _196jrpp2d _196jrpp2h">
                  Streamline your video creation process
                </p>
              </div>
            </div>
            <div className="css-15vqpxh">
              <div className="css-39hwd0">
                <div className="topaigenerateInput">
                  <input
                    type="text"
                    placeholder="Enter your own title or topic..."
                    className="aigenerateInput"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleGenerate();
                      }
                    }}
                  />
                </div>
                <Button
                  handleClick={() => {
                    handleGenerate();
                  }}
                  disabled={title?.length <= 0}
                  title="Generate"
                  loading={false}
                  className=""
                />
                {/* <button
                  className="generateBtn zq9waw0 zq9waw1 zq9waw8"
                  type="button"
                  onClick={() => {
                    handleGenerate();
                  }}
                >
                  <span>Generate</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            paddingTop: "5rem",
            paddingBottom: "5rem",
            color: "white",
            fontFamily: "sans-serif",
          }}
        >
          <div
            className={`bg-box  ${
              loading ? "blink access-filter bg-loading-effect" : ""
            }`}
          >
            <input
              type="text"
              className="keyword-input"
              placeholder="Enter keyword..."
            />
            <p className="instruction-text">Use top related keywords</p>
            <div className="keywords">
              {scriptData?.keywords?.map((keyword, index) => (
                <button key={index} className="keyword-button">
                  {keyword}
                </button>
              ))}
            </div>
            <button className="submit-button">Submit</button>
          </div>{" "}
          <div
            className={`bg-box  ${
              loading ? "blink access-filter bg-loading-effect" : ""
            }`}
          >
            <div className="title-header">
              <span className="title-text">Title</span>
              <button
                className="copy-icon"
                onClick={() => {
                  // Generate text to be copied from scriptData
                  const titleText =
                    scriptData?.title || "No description available";

                  // Copy to clipboard
                  navigator.clipboard.writeText(titleText);
                  triggerNotification({
                    type: "success",
                    message: "Copied to clipboard",
                    duration: 3000,
                    animation: "pop",
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-copy h0f1ft27 h0f1ft2c"
                >
                  <rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
                  <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                </svg>
              </button>
            </div>
            <p className="actual-title">{scriptData?.title}</p>
          </div>
          <div
            className={`bg-box  ${
              loading ? "blink access-filter bg-loading-effect" : ""
            }`}
          >
            <div className="description-header">
              <span className="description-text">Description</span>
              <button
                className="copy-icon"
                onClick={() => {
                  // Generate text to be copied from scriptData
                  const descriptionText =
                    scriptData?.description || "No description available";

                  // Copy to clipboard
                  navigator.clipboard.writeText(descriptionText);
                  triggerNotification({
                    type: "success",
                    message: "Copied to clipboard",
                    duration: 3000,
                    animation: "pop",
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-copy h0f1ft27 h0f1ft2c"
                >
                  <rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
                  <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                </svg>
              </button>
            </div>
            <p className="actual-description">{scriptData?.description}</p>
          </div>
          <div
            className={`bg-box  ${
              loading ? "blink access-filter bg-loading-effect" : ""
            }`}
          >
            <div className="tag-header">
              <span className="tag-text">Tags</span>
              <button
                className="copy-icon"
                onClick={() => {
                  // Generate comma-separated tags string
                  const tagsString = scriptData?.tags
                    ?.map((tag) => tag) // Assuming tag.name is the property with the tag text
                    .join(", "); // Join with comma and space

                  // Copy to clipboard
                  navigator.clipboard.writeText(tagsString);
                  triggerNotification({
                    type: "success",
                    message: "Copied to clipboard",
                    duration: 3000,
                    animation: "pop",
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-copy h0f1ft27 h0f1ft2c"
                >
                  <rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
                  <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                </svg>
              </button>
            </div>
            <div className="tags">
              {scriptData?.tags?.map((tag, index) => (
                <button key={index} className="tag-button">
                  {tag}{" "}
                  <span className={`tag-number ${getNumberColor(tag.number)}`}>
                    {tag.number}
                  </span>
                </button>
              ))}
            </div>
          </div>
          <div
            className={`bg-box  ${
              loading ? "blink access-filter bg-loading-effect" : ""
            }`}
          >
            <div className="hook-header">
              <span className="hook-text">Hook</span>
              <button
                className="copy-icon"
                onClick={() =>
                  navigator.clipboard.writeText("Actual Hook Content")
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-copy h0f1ft27 h0f1ft2c"
                >
                  <rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
                  <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                </svg>
              </button>
            </div>
            <p className="actual-hook">{scriptData?.hook}</p>
          </div>
          <div
            className={`bg-box  ${
              loading ? "blink access-filter bg-loading-effect" : ""
            }`}
          >
            <div className="outline-header">
              <span className="outline-text">Outlines</span>
              <button
                className="copy-icon"
                onClick={() => {
                  // Generate formatted string for segments with one line space between each
                  const segmentsString = segments
                    ?.map(
                      (segment) =>
                        `${segment.title} (${segment.duration}): ${segment.content}`
                    )
                    .join("\n\n"); // Join with double newline for one line space between segments

                  // Copy to clipboard
                  navigator.clipboard.writeText(segmentsString);
                  triggerNotification({
                    type: "success",
                    message: "Copied to clipboard",
                    duration: 3000,
                    animation: "pop",
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-copy h0f1ft27 h0f1ft2c"
                >
                  <rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
                  <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                </svg>
              </button>
            </div>
            <div className="segments">
              {segments?.map((segment, index) => (
                <div key={index} className="segment-box">
                  <div className="segment-header">
                    <span className="segment-title">{segment.title}</span>
                    <span className="segment-duration">{segment.duration}</span>
                  </div>
                  <p className="segment-content">{segment.content}</p>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`bg-box  ${
              loading ? "blink access-filter bg-loading-effect" : ""
            }`}
          >
            <div className="script-header">
              <span className="script-description-text">Script</span>
              <button
                className="copy-icon"
                onClick={() => navigator.clipboard.writeText(scriptContent)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-copy h0f1ft27 h0f1ft2c"
                >
                  <rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
                  <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                </svg>
              </button>
            </div>
            <div className="script-content">
              <p>{scriptData?.script}</p>
            </div>
          </div>
          <button className="get-ideas-button">Get more video ideas</button>
        </div>
      )}
    </>
  );
};

export default DetailedAnalyticsPage;
