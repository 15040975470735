/*global chrome*/
import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";
import Dashboard from "./dashboard/Dashboard";
import AccountSettings from "./dashboard/AccountSettings";
import Contact from "./dashboard/Contact";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie"; // You may need to install this library
import { baseUrl } from "../utils/config";
import OptimizationPage from "./optimization/OptimizationPage ";
import DetailOptimizePage from "./optimization/DetailOptimizePage";
import NotepadMain from "./notepad/NotepadMain";
import DetailedAnalyticsPage from "./DetailedAnalyticsPage";
import "./Desktop.css";
import useApiService from "../actions/useApiService";
import SubscriptionModal from "./dashboard/SubscriptionModal";
import { useLocation } from "react-router-dom";
import { baseextensionId } from "../utils/config";

const Desktop = () => {
  console.log("baseextensionId", baseextensionId);
  const { userInfoData } = useApiService();
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { details, section } = useParams(); // Get the section parameter from the URL
  const [status, setStatus] = useState(true); // add subscription later
  const [load, setLoad] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [selectedSection, setSelectedSection] = useState(
    section || "dashboard"
  );

  useEffect(() => {
    const fetchData = async () => {
      // Check if the URL contains 'dashboard' and 'code'
      if (
        location.pathname.includes("dashboard") &&
        location.search.includes("code")
      ) {
        try {
          const data = await userInfoData();
          setUserInfo(data);

          handleUriCallback(data.email);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }

      // Handle logout
      if (location.pathname.includes("logout")) {
        Cookies.remove("token");
        window.location.reload();
      }
    };

    fetchData();
  }, [location]); // Ensure userInfoData is included in dependencies

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const fetchDataFromApi = async () => {
    try {
      setLoad(true);
      const data = await userInfoData();

      if (
        data?.hasAccess
        // || subscriptionStatus == "active"
      ) {
        setStatus(true);
      }
      setLoad(false);
      if (data?.error === "unauthorized") {
        handleUnauthorized(data);
      } else {
        setUserInfo(data);
        sendMessageToExtension(data);
      }
    } catch (error) {
      console.error("Dashboard request error:", error);
      setLoad(false);
    }
  };

  const sendMessageToExtension = (data) => {
    const extensionId = baseextensionId;
    // const extensionId = "ikheknefdppkmbagcblipglpalohcfjm";

    // const extensionId = "ecnepininffodhhimnlocaehgpmcnnlk";
    if (chrome?.runtime?.sendMessage) {
      chrome.runtime.sendMessage(
        extensionId,
        {
          accessToken: token,
          email: data?.email,
          userId: data?._id,
          uasageMetrics: data?.uasageMetrics,
          type: "loggedInUser",
        },
        function (response) {
          if (chrome.runtime.lastError) {
            console.error(chrome.runtime.lastError);
          } else {
            console.log("Received response from extension:", response);
          }
        }
      );
    }
  };

  const handleUnauthorized = (data) => {
    console.log("Unauthorized");
    Cookies.remove("token");
    // const extensionId = "ecnepininffodhhimnlocaehgpmcnnlk";
    const extensionId = baseextensionId;
    // const extensionId = "ikheknefdppkmbagcblipglpalohcfjm";
    if (chrome?.runtime?.sendMessage) {
      chrome.runtime.sendMessage(
        extensionId,
        {
          accessToken: "",
          email: data?.email,
          userId: data?._id,
          type: "loggedInUser",
        },
        function (response) {
          if (chrome.runtime.lastError) {
            console.error(chrome.runtime.lastError);
          } else {
            console.log("Received response from extension:", response);
          }
        }
      );
    }
    Cookies.remove("token");
    navigate("/login");
  };

  const handleSectionClick = (section) => {
    setSelectedSection(section);
    navigate(`/${section}`);
  };

  const handleUriCallback = (email) => {
    const queryString = window.location.href.split("?")[1];
    if (queryString) {
      const queryParams = queryString.split("&").reduce((acc, curr) => {
        const [key, value] = curr.split("=");
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
      const authorizationCode = queryParams["code"];
      if (authorizationCode) {
        fetchYouTubeData(authorizationCode, email);
      }
    }
  };

  const fetchYouTubeData = async (authorizationCode, email) => {
    console.log("desktop");
    const payload = {
      code: authorizationCode,
      clientId:
        "813900024630-q5j3kfav0s0222fi7jk7qhurgls3a9ea.apps.googleusercontent.com",
      clientSecret: "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",
      redirectUri: "https://app.tubeinsights.pro/dashboard",
      email: email,
    };

    try {
      const response = await fetch(`${baseUrl}/exchange-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log("Success3:", response);
        navigate("/dashboard");
        // const data = await userInfoData();

        window.location.reload(); //temp solution for refresh after account link
      } else {
        console.error("Failed:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className={`desktop-container`}>
        {/* from here to remove the bottom logout this combined line */}

        <div className="combined-container">
          <div className="left-nav">
            <div
              className={`nav-item ${
                selectedSection === "dashboard" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("dashboard")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="cursor-pointer-navicons"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              {/* <span>Dashboard</span> */}
            </div>
            <div
              className={`nav-item ${
                selectedSection === "ideas" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("ideas")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                class="cursor-pointer-navicons"
              >
                <path
                  fill-rule="evenodd"
                  d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div
              className={`nav-item ${
                selectedSection === "videos" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("videos")}
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="h-4 w-4"
              >
                <rect width="18" height="18" x="3" y="3" rx="2"></rect>
                <path d="M9 8h7"></path>
                <path d="M8 12h6"></path>
                <path d="M11 16h5"></path>
              </svg>
            </div>
            {/* <div
              className={`nav-item ${
                selectedSection === "notepad" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("notepad")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="h-4 w-4"
              >
                <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
              </svg>
            </div> */}
            <div
              className={`nav-item ${
                selectedSection === "account" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("account")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                class="cursor-pointer-navicons"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              {/* <span>Account</span>  */}
            </div>
            <div
              className={`nav-item ${
                selectedSection === "contact" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("contact")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                class="cursor-pointer-navicons"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
              {/* <span>Contact</span> */}
            </div>

            {/* <div
          className={`nav-item ${
            selectedSection === "settings" ? "selected" : ""
          }`}
          onClick={() => handleSectionClick("settings")}
        >
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            class="cursor-pointer-navicons"
          >
            <path
              fill-rule="evenodd"
              d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div> */}
          </div>
          {/* from here to remove the bottom logout */}
          <div className="bottom-div-container">
            {/* Your bottom-div content goes here */}
            <div
              className={`nav-item ${
                selectedSection === "dashboard" ||
                "account" ||
                "contact" ||
                "notepad"
                  ? "selected"
                  : ""
              }`}
              onClick={() => {
                Cookies.remove("token");
                window.location.reload();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="cursor-pointer-navicons"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              {/* <span>Dashboard</span> */}
            </div>
          </div>
          {/* from here to remove the bottom logout */}
        </div>
        {/* <div className="right_side_content"> */}
        {/* <TopNavBar userInfo={userInfo} /> */}
        {status ? (
          <div className="right-content">
            {selectedSection === "dashboard" && (
              <Dashboard userInfo={userInfo} load={load} />
            )}
            {console.log("userInfol", userInfo)}
            {/* {selectedSection == "ideas" ? (
          <OptimizationPage userInfo={userInfo} />
        ) : (
          <></>
        )} */}
            {section === "videos" && !details && (
              <OptimizationPage userInfo={userInfo} />
            )}

            {section === "videos" && details && (
              <DetailOptimizePage userInfo={userInfo} load={load} />
            )}
            {section === "ideas" && (
              <DetailedAnalyticsPage userInfo={userInfo} />
            )}

            {selectedSection == "account" && (
              <AccountSettings userInfo={userInfo} />
            )}
            {selectedSection == "contact" ? (
              <Contact userInfo={userInfo} />
            ) : (
              <></>
            )}

            {selectedSection == "notepad" ? (
              <NotepadMain userInfo={userInfo} />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <SubscriptionModal onClose={() => {}} />
        )}
        {/* </div> */}

        {/* <div className="dark-mode-toggle">
        <label>
          <input
            type="checkbox"
            checked={isDarkMode}
            onChange={() => setIsDarkMode(!isDarkMode)}
          />
          Dark Mode
        </label>
      </div> */}
      </div>
    </>
  );
};

export default Desktop;
