import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./optimizationPage.css"; // Import the CSS file
import YoutubeCard from "../common/YoutubeCard";
import Button from "../common/Button";
import { callApi } from "../../utils/api";

const OptimizationPage = ({ userInfo }) => {
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const email = "ishaiknadeem@gmail.com"; // Provide the email here
        const params = { email };
        const data = await callApi("POST", "/channel-videos", params);
        setVideoData(data.latest_20_videos);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching video data:", error);
        setLoading(false); // Ensure loading state is set to false even on error
      }
    };

    fetchVideoData();
  }, [userInfo.email]);
  return (
    <>
      {userInfo?.channels?.length > 0 ? (
        <div className="optimize-main-dashboard">
          <div className="card-container">
            <YoutubeCard
              videoData={videoData}
              loading={loading}
              skeletoncount={12}
            />
          </div>
        </div>
      ) : (
        <div className="plz-cnt-chnl">
          Please connect your channel to see the video optimization tools.
        </div>
      )}
    </>
  );
};

export default OptimizationPage;
