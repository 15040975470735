// client/src/pages/Login/Login/LoginPage.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import AuthService from "../../../services/AuthService";
import GoogleLoginComponent from "../../../components/GoogleLoginButton";
import "./LoginPage.css";
import { Link } from "react-router-dom";

const LoginPage = ({ onLogin }) => {
  const navigate = useNavigate();
  const { login, token } = useAuth();
  const [email, setEmail] = useState("www@www.www");
  const [password, setPassword] = useState("www");

  // If the user is already authenticated, redirect to the desktop page
  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token, navigate]);

  const handleLogin = async () => {
    try {
      const token = await AuthService.login(email, password);
      console.log("got token", token);
      login(token);
      // onLogin(token); // Redirect to the desired route
      navigate("/dashboard");
    } catch (error) {
      console.error("Login failed:", error.message);
      // Handle login failure (e.g., show error message to the user)
    }
  };

  const handleGoogleLoginSuccess = async (tokenId) => {
    try {
      // Send the Google token to your server for verification
      const accessToken = await AuthService.verifyGoogleToken(tokenId);
      onLogin(accessToken); // Redirect to the desired route
      navigate("/dashboard");
      // Optionally, perform additional actions after successful login
    } catch (error) {
      console.error("Google login failed:", error.message);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failed:", error);
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  return (
    <>
      <div className="login-container">
        <div className="ml-3 top-header-line d-none ">
          <h1 className="app-name"></h1>

          <header className="floating-header">
            <nav className="nav-options">
              <Link to="/" className="nav-link">
                Home
              </Link>
              <Link to="/about" className="nav-link">
                About
              </Link>
              {/* <Link to="/terms" className="nav-link">
                Terms
              </Link> */}
              <Link to="/privacy" className="nav-link">
                Privacy
              </Link>
            </nav>
            <div className="auth-options">
              <Link to="/login">Sign In</Link>
              {/* <Link to="/signup">Sign Up</Link> */}
            </div>
          </header>
        </div>

        <div className="login-box">
          <h2 className="login-header">Hi, Welcome back!</h2>

          <p
            style={
              {
                // marginBottom: "25px",
              }
            }
          >
            Log in for instant access. No sign-up needed.
          </p>
          {/* <div className="input-group">
            <label className="input-group-label">Email:</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div> */}
          {/* <div className="input-group">
            <label className="input-group-label">Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div> */}
          {/* <button className="login-button" onClick={handleLogin}>
            Login
          </button> */}
          {/* <div
            style={{
              textAlign: "center",
              marginTop: "15px",
              fontSize: "14px",
            }}
          >
            {" "}
            OR
          </div> */}
          <GoogleLoginComponent onLogin={onLogin} />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
