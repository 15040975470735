import React, { useState } from "react";
import Modal from "react-modal";
import "./MyModal.css"; // Import the CSS file for custom styles

// Set the app element for accessibility
Modal.setAppElement("#root");

const MyModal = ({
  isOpen,
  closeModal,
  modalTitle,
  children,
  removeHeader,
  color,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      overlayClassName="modal-overlay"
      className="modal-content"
      style={{
        content: {
          background: color ? color : "white",
        },
      }}
    >
      <div className={`modal-header ${removeHeader ? "d-none" : ""} `}>
        <h2>{modalTitle}</h2>
        <button className="close-btn" onClick={closeModal}>
          &times;
        </button>
      </div>
      <div className="modal-body">{children}</div>
    </Modal>
  );
};

export default MyModal;
