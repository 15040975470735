import React from "react";
import "./RecTangleContentBox.css";
const RecTangleContentBox = ({ connectedChannel }) => {
  const handleSignIn = () => {
    const clientId =
      "813900024630-q5j3kfav0s0222fi7jk7qhurgls3a9ea.apps.googleusercontent.com";

    const redirectUri = "https://app.tubeinsights.pro/dashboard"; // Redirect URI configured in Google Cloud Console
    const scopes = [
      "https://www.googleapis.com/auth/youtube",
      "https://www.googleapis.com/auth/yt-analytics-monetary.readonly",
      "https://www.googleapis.com/auth/yt-analytics.readonly",
      "https://www.googleapis.com/auth/youtube.readonly",
      // "https://www.googleapis.com/auth/youtube.force-ssl",
    ];

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${encodeURIComponent(
      scopes.join(" ")
    )}&access_type=offline&prompt=consent`;

    window.location.href = authUrl;
  };

  return (
    <div className="component-container">
      <div className="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100"
          height="100"
          viewBox="0 0 64 64"
        >
          <linearGradient
            id="PMWUiMSnUf2R_PPJ6rqZea_44112_gr1"
            x1="32"
            x2="32"
            y1="10"
            y2="54.751"
            gradientUnits="userSpaceOnUse"
            spreadMethod="reflect"
          >
            <stop offset="0" stop-color="#1a6dff"></stop>
            <stop offset="1" stop-color="#c822ff"></stop>
          </linearGradient>
          <path
            fill="url(#PMWUiMSnUf2R_PPJ6rqZea_44112_gr1)"
            d="M32,53.001c-6.358,0-12.716-0.195-19.299-0.584c-4.96-0.293-8.961-4.242-9.308-9.187 c-0.521-7.452-0.521-15.009-0.001-22.46c0.348-4.945,4.349-8.895,9.309-9.188c13.167-0.778,25.433-0.778,38.598,0 c4.96,0.293,8.961,4.242,9.308,9.187c0.521,7.452,0.521,15.009,0.001,22.46c-0.348,4.945-4.349,8.895-9.309,9.188l0,0 C44.716,52.806,38.358,53.001,32,53.001z M51.182,50.421l0.059,0.998L51.182,50.421c3.959-0.234,7.153-3.386,7.431-7.331 c0.515-7.358,0.515-14.821-0.001-22.181c-0.276-3.944-3.471-7.096-7.43-7.33c-13.088-0.773-25.275-0.773-38.363,0 c-3.959,0.234-7.153,3.386-7.431,7.331c-0.515,7.358-0.515,14.821,0.001,22.181c0.276,3.944,3.471,7.096,7.43,7.33 C25.906,51.194,38.094,51.194,51.182,50.421z"
          ></path>
          <linearGradient
            id="PMWUiMSnUf2R_PPJ6rqZeb_44112_gr2"
            x1="31.72"
            x2="31.72"
            y1="24.25"
            y2="39.252"
            gradientUnits="userSpaceOnUse"
            spreadMethod="reflect"
          >
            <stop offset="0" stop-color="#6dc7ff"></stop>
            <stop offset="1" stop-color="#e6abff"></stop>
          </linearGradient>
          <path
            fill="url(#PMWUiMSnUf2R_PPJ6rqZeb_44112_gr2)"
            d="M25 39.84L25 24.16 38.44 32z"
          ></path>
          <linearGradient
            id="PMWUiMSnUf2R_PPJ6rqZec_44112_gr3"
            x1="32.212"
            x2="32.212"
            y1="10"
            y2="54.751"
            gradientUnits="userSpaceOnUse"
            spreadMethod="reflect"
          >
            <stop offset="0" stop-color="#1a6dff"></stop>
            <stop offset="1" stop-color="#c822ff"></stop>
          </linearGradient>
          <path
            fill="url(#PMWUiMSnUf2R_PPJ6rqZec_44112_gr3)"
            d="M24,41.581V22.419L40.425,32L24,41.581z M26,25.901v12.197L36.456,32L26,25.901z"
          ></path>
        </svg>
      </div>
      <div className="text">
        <p>
          {connectedChannel
            ? "Channel Connected"
            : "Please connect yout Channel "}
        </p>
        {connectedChannel == false && <p>Connect Your YouTube Channel</p>}
      </div>

      {connectedChannel ? (
        <>
          <div className="icon bg-green">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          </div>
        </>
      ) : (
        <div className="button">
          <button
            className="arrowButton2 next actionButtonBounce"
            onClick={handleSignIn}
          >
            Connect
            {/* &#8594; */}
          </button>
        </div>
      )}
    </div>
  );
};

export default RecTangleContentBox;
