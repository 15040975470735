import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const formatValueToK = (value) => {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`; // Convert to millions
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}k`; // Convert to thousands
  }
  return value.toString();
};

export default class StackedAreaChart extends PureComponent {
  render() {
    const { viewsanalyticsData, singleChart } = this.props;

    const halfLength = Math.ceil(viewsanalyticsData?.length / 2); // Calculate half of the length and round up
    const modifiedData = [];

    for (let i = 0; i < halfLength; i++) {
      const firstIndex = i;
      const secondIndex = i + halfLength;

      if (secondIndex < viewsanalyticsData?.length) {
        const firstItem = viewsanalyticsData[firstIndex];
        const secondItem = viewsanalyticsData[secondIndex];

        const combinedItem = {
          Day: firstItem.Day,
          Previous: firstItem.Views,
          Present: secondItem.Views,
        };

        modifiedData.push(combinedItem);
      } else {
        // If there's no second item available for the last iteration (odd number of items)
        const firstItem = viewsanalyticsData[firstIndex];
        const combinedItem = {
          Day: firstItem.Day,
          Previous: firstItem.Views,
        };

        modifiedData.push(combinedItem);
      }
    }
    return (
      <div style={{ height: "270px", margin: "0 auto" }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={singleChart == 2 ? viewsanalyticsData : modifiedData}
            margin={{ top: 0, right: 0, left: -60, bottom: -35 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#914d67" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#914d67" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="Day" axisLine={false} tick={false} />
            <YAxis
              orientation="left"
              axisLine={false}
              tickFormatter={formatValueToK}
              domain={[0, "auto"]} // Automatically adjust the domain based on data range
            />
            <CartesianGrid stroke="none" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="Previous"
              stroke="#f4749a"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
            <Area
              type="monotone"
              dataKey={singleChart == 2 ? "Views" : "Present"}
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
