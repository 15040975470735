/*global chrome*/
import Cookies from "js-cookie";
import { baseUrl } from "../utils/config";
import { baseextensionId } from "../utils/config";
const login = async (email, password) => {
  try {
    const url = `${baseUrl}/login`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error("Login failed");
    }

    const data = await response.json();
    const accessToken = data.accessToken;
    // Your website script..
    console.log("lol1");

    try {
      console.log("lol");

      // Replace 'YOUR_EXTENSION_ID' with your Chrome extension ID
      // const extensionId = "ecnepininffodhhimnlocaehgpmcnnlk";
      const extensionId = baseextensionId;
      // const extensionId = "ikheknefdppkmbagcblipglpalohcfjm";

      chrome.runtime.sendMessage(
        extensionId,
        { accessToken: accessToken, type: "loggedInUser" },
        function (response) {
          if (chrome.runtime.lastError) {
            console.log("lol3");

            console.error(chrome.runtime.lastError);
          } else {
            console.log("Received response from extension:", response);
          }
        }
      );
    } catch (extensionError) {
      console.error("Error sending message to extension:", extensionError);
      console.log("lol2");

      // Handle the error as needed, e.g., log it or ignore it
    }

    return accessToken;
  } catch (error) {
    throw new Error("Login failed");
  }
};

const signup = async (email, password) => {
  try {
    const url = `${baseUrl}/signup`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    // Optionally, you can handle the signup success scenario here
  } catch (error) {
    throw new Error("Signup failed");
  }
};

const verifyGoogleToken = async (googleToken) => {
  try {
    const url = `${baseUrl}/verifyGoogleToken`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ googleToken }),
    });

    if (!response.ok) {
      throw new Error("Google token verification failed");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error verifying Google token:", error.message);
    throw new Error("Google token verification failed");
  }
};

const isTokenValid = (token) => {
  // Implement your logic to check token validity, e.g., check expiration
  // Return true if valid, false otherwise
  return true; // Placeholder, replace with your actual logic
};

const getToken = () => {
  return Cookies.get("token");
};

const clearToken = () => {
  Cookies.remove("token");
};

const AuthService = {
  login,
  getToken,
  isTokenValid,
  signup,
  clearToken,
  verifyGoogleToken,
};

export default AuthService;
