import React from "react";
import "./TileSquareComponent.css";

const CtaBox = ({}) => {
  return (
    <div className="cta-box">
      <div className="rainbow-border">
        <div className="content2">
          <h2 className="heading">Your Heading Here</h2>
          <p className="subtitle2">
            Want to see your best time to publish? Understand when is the best
            time to post to maximize your reach.
          </p>
          <button className="cta-button">Call to Action</button>
        </div>
      </div>
    </div>
  );
};

export default CtaBox;
