// client/src/components/MobileWarning.js
import React from "react";
import "./MobileWarning.css";

const MobileWarning = () => {
  return (
    <div className="mobile-warning-container">
      <p>Please use a computer to access this website.</p>
    </div>
  );
};

export default MobileWarning;
