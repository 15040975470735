// client/src/App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./AuthContext";
import Home from "./pages/Login/Home/Home";
import LoginPage from "./pages/Login/Login/LoginPage";
import Desktop from "./components/DesktopPage";
import AuthService from "./services/AuthService";
import SignUpPage from "./pages/Login/signup/SignUpPage";
import PrivacyPolicy from "./pages/Login/PrivacyPolicy";
import TermsOfService from "./pages/Login/TermsOfService";
import MobileWarning from "./components/MobileWarning";
const PrivateRoute = ({ element, ...rest }) => {
  const { token } = useAuth();
  console.log("token3", token);
  return token ? element : <Navigate to="/login" />;
};

const App = () => {
  const { login } = useAuth();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Check if the screen width is below a certain threshold to determine if it's a mobile device
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 997); // You can adjust the threshold as needed
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLogin = async (token) => {
    login(token);
    const isTokenValid = await AuthService.isTokenValid(token);

    if (!isTokenValid) {
      AuthService.clearToken();
    }
  };
  return (
    <div>
      {isMobile ? (
        <MobileWarning />
      ) : (
        <Router>
          {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/signup">Sign Up</Link>
            </li>
            <li>
              <Link to="/desktop">Desktop</Link>
            </li>
          </ul>
        </nav> */}

          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<LoginPage onLogin={handleLogin} />} />
            <Route
              path="/login"
              element={<LoginPage onLogin={handleLogin} />}
            />{" "}
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            {/* <Route path="/signup" element={<SignUpPage />} /> */}
            <Route
              path="/:section"
              element={<PrivateRoute element={<Desktop />} />}
            />
            {/* <Route
              path="/:section"
              element={<PrivateRoute element={<Desktop />} />}
            />
            <Route
              path="/:section"
              element={<PrivateRoute element={<Desktop />} />}
            /> */}
            <Route
              path="/:section/:details/:videoid"
              element={<PrivateRoute element={<Desktop />} />}
            />
          </Routes>
        </Router>
      )}
    </div>
  );
};

export default App;
