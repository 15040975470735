// // On the client side

// import React, { useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";

// const SubscriptionModal = () => {
//   const handleSubscribe = async () => {
//     const stripe = await loadStripe(
//       "pk_test_51OVCPOSGWfdi7rWoumVAtgp9oNBPV9MA63CuxH3w0xnPdLLI6psr6KtbXGfSVdaeeKLQhEl0E4nkmNtB4soOfuXV00J6HBP5sv"
//     );
//     const body = {
//       priceId: "price_1OVGqMSGWfdi7rWoArVSEB9c",
//     };
//     const headers = {
//       "Content-Type": "application/json",
//     };

//     try {
//       // Create a checkout session
//       const response = await fetch(
//         "http://localhost:3001/create-checkout-session",
//         {
//           method: "POST",
//           headers: headers,
//           body: JSON.stringify(body),
//         }
//       );

//       const session = await response.json();

//       console.log("stripe", session);

//       const { error } = await stripe.redirectToCheckout({
//         sessionId: session.sessionId,
//       });

//       if (error) {
//         console.error("Error redirecting to Checkout:", error);
//       }
//     } catch (error) {
//       console.error("Error creating checkout session:", error);
//     }
//   };

//   return (
//     <div>
//       {/* Your subscription modal content here */}
//       <button onClick={handleSubscribe}>Subscribe</button>
//     </div>
//   );
// };

// export default SubscriptionModal;

// client/src/pages/Dashboard/SubscriptionModal.js
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import "./SubscriptionModal.css";

const SubscriptionModal = ({ removeSomeCss }) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await loadStripe(
        "pk_test_51OVCPOSGWfdi7rWoumVAtgp9oNBPV9MA63CuxH3w0xnPdLLI6psr6KtbXGfSVdaeeKLQhEl0E4nkmNtB4soOfuXV00J6HBP5sv"
      );
      setStripe(stripeInstance);
    };

    initializeStripe();
  }, []);

  const handleSubscribe = () => {
    window.location.href = "  https://buy.stripe.com/test_3cs038eu8ejK7BubIJ";
  };
  // const handleSubscribe = async () => {
  //   if (!stripe) {
  //     console.error("Stripe not initialized");
  //     return;
  //   }

  //   const body = {
  //     priceId: "price_1OsU0NSGWfdi7rWoFi6sZqY0",
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };

  //   try {
  //     // Create a checkout session
  //     const response = await fetch(
  //       "http://localhost:3001/create-checkout-session",
  //       {
  //         method: "POST",
  //         headers: headers,
  //         body: JSON.stringify(body),
  //       }
  //     );

  //     const session = await response.json();

  //     console.log("stripe", session);

  //     const { error } = await stripe.redirectToCheckout({
  //       sessionId: session.sessionId,
  //     });

  //     if (error) {
  //       console.error("Error redirecting to Checkout:", error);
  //     }
  //   } catch (error) {
  //     console.error("Error creating checkout session:", error);
  //   }
  // };

  const features = [
    "Premium Support When You Need It ",
    "Unleash Your Creative with all Tools",
    "Unlimited Video Titles",
    "Unlimited Video Descriptions",
    "Unlimited Video Tags",
    "Access to all tools and features",
    // "Dive into a World of Endless Possibilities",
    "Coming Soon: Stunning Thumbnails generation at Your Fingertips",
  ];

  // tempo change removeSomeCss to remove upar overlay layer later add this in modal a remove it
  return (
    <div className={`${removeSomeCss ? "" : "subscription-modal-overlay"}`}>
      <div className="subscription-modal-glossy-overlay">
        <h2 className="subscribe-heading-text">
          Unlock Premium Features - Subscribe Now!
        </h2>
        <div className="subscription-modal">
          {/* <div className="features-list">
            <div className="feature-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                className="tick-green-svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="badge">Premium Support</span>
            </div>
            <div className="feature-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                className="tick-green-svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="badge">Unlock Every Tool</span>
            </div>
            <div className="feature-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                className="tick-green-svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="badge">Unlimited Video Tags</span>
            </div>
            <div className="feature-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                className="tick-green-svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="badge">Unlimited Video Titles</span>
            </div>

            <div className="feature-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                className="tick-green-svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="badge">Unlimited Video Descriptions</span>
            </div>
            <div className="feature-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                className="tick-green-svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="badge">Access to all tools and features</span>
            </div>
            <div className="feature-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                className="tick-green-svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="badge">
                Thumbnail Generation (upcoming feat)
              </span>
            </div>
          </div> */}
          <div className="features-list">
            {features.map((feature, index) => (
              <div className="feature-item" key={index}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="tick-green-svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="badge">{feature}</span>
              </div>
            ))}
          </div>
          <div className="subscription-details">
            <div className="price-container">
              <h2 className="price-heading">$19</h2>
              <small className="text-price"> / month</small>
            </div>

            <div className="subscribe-button">
              <button
                style={{
                  cursor: "no-drop",
                }}
                onClick={handleSubscribe}
                disabled={true}
              >
                Subscribe
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  class="arrow-right-svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <small className="text-center text-xs">
              Total billed today: $19
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModal;
