import React, { useState } from "react";
import PropTypes from "prop-types";
import "./TileSquareComponent.css";
import CtaBox from "./CtaBox";
import ToggleButtons from "./ToggleButtons";
import ProgressBar from "./ProgressBar";
import Button from "./Button";
import CustomShapePieChart from "./CustomShapePieChart";
// import CustomConeBarChart from "./CustomConeBarChart";

const TileSquareComponent = ({
  title,
  buttonTitle,
  handleButtonClick,
  subtitle,
  message,
  inputPlaceholder,
  subscribersRatio,
  watchTimeRatio,
  subcount,
  watchtimecount,
  retentionRatio,
  retentionCount,
  subscribersCount,
  watchTimeCount,
  ViewscountRatio,
  retention,
  subscribers,
  watchTime,
  cta,
  barchart,
  piChart,
  demographics,
  ChannelStats,
  analyticsData,
  loading,
  button1Text,
  button2Text,
  handlegetChanelStats,
  keywordsBetaAccess,
  disabled,
  className,
  flag,
  CustomPieChart,
  titleCount,
  descriptionCount,
  tagsCount,
  progressData,
}) => {
  const [selectedDay, setSelectedDay] = useState("Monday"); // Default selected day is Monday
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const { gender } = analyticsData || {};

  const genderAgeData = {};

  (gender?.rows || []).forEach((row) => {
    const [ageGroup, gender, viewerPercentage] = row;
    const percentage = parseFloat(viewerPercentage); // Parse viewer percentage as float

    // Initialize gender object if it doesn't exist
    if (!genderAgeData[gender]) {
      genderAgeData[gender] = { totalPercentage: 0, ageGroups: {} };
    }

    // Update total percentage for the gender
    genderAgeData[gender].totalPercentage += percentage;

    // Initialize age group object if it doesn't exist
    if (!genderAgeData[gender].ageGroups[ageGroup]) {
      genderAgeData[gender].ageGroups[ageGroup] = 0;
    }

    // Update percentage for the age group within the gender
    genderAgeData[gender].ageGroups[ageGroup] += percentage;
  });

  // Round total percentage within each gender
  Object.keys(genderAgeData).forEach((gender) => {
    genderAgeData[gender].totalPercentage = Math.round(
      genderAgeData[gender].totalPercentage
    );
  });

  console.log("genderAgeData", genderAgeData, analyticsData);

  const handleMouseEnter = (country) => {
    setHoveredCountry(country);
  };

  const handleMouseLeave = () => {
    setHoveredCountry(null);
  };

  const changeDay = (direction) => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const currentIndex = days.indexOf(selectedDay);
    let newIndex;
    if (direction === "prev") {
      newIndex = currentIndex === 0 ? days.length - 1 : currentIndex - 1;
    } else {
      newIndex = currentIndex === days.length - 1 ? 0 : currentIndex + 1;
    }
    setSelectedDay(days[newIndex]);
  };

  const timesToPost = [3, 5, 12, 3, 21, 5, 5, 12, 3, 5, 5, 2, 5, 12, 3, 21];
  const getSliceRotation = (data, index) => {
    const totalPercentage = data.reduce(
      (acc, curr) => acc + curr.percentage,
      0
    );
    let angle = 0;
    for (let i = 0; i < index; i++) {
      angle += (360 * data[i].percentage) / totalPercentage;
    }
    return angle;
  };

  return (
    <>
      <div className={`tile-square-component ${cta ? "access-filter" : ""}`}>
        <div className="box-header">
          {title && <h3 className="title-head">{title}</h3>}
          {buttonTitle && (
            <Button
              handleClick={(flag) => {
                handleButtonClick(flag);
              }}
              disabled={disabled}
              flag={flag}
              title={buttonTitle}
              loading={loading}
              className={className}
            />
          )}
          {button1Text && button2Text && (
            <ToggleButtons
              handleClick={(buttonNumber) => {
                handlegetChanelStats(buttonNumber);
              }}
              button1Text={button1Text}
              button2Text={button2Text}
            />
          )}
        </div>

        <div class="horizontalLine"></div>

        {subtitle && (
          <div className={`subtitle ${loading ? "blink access-filter" : ""} `}>
            {subtitle}
          </div>
        )}

        {message && <div className="message">{message}</div>}

        <div className="progress-bars ">
          {subscribers && (
            <div
              className={`progress-bar ${
                loading ? "blink access-filter" : ""
              } `}
            >
              <div className="progress-details">
                {/* <div className="progress-value">{subscribers}/</div> */}
                <div className="progress-value">
                  <span style={{ fontWeight: "bold", color: "white" }}>
                    {" "}
                    {subscribers}
                  </span>
                  <span>/1000</span>
                </div>
                <div className="progress-label">Subscribers</div>
              </div>
              <div className="progress-container">
                <div
                  className="progress-fill"
                  style={{
                    width: `${subscribers ? (subscribers / 1000) * 100 : 0}%`,
                  }}
                ></div>
              </div>
            </div>
          )}

          {watchTime && (
            <div
              className={`progress-bar ${
                loading ? "blink access-filter" : ""
              } `}
            >
              {" "}
              <div className="progress-details">
                {/* <div className="progress-value">{watchTime}</div> */}
                <div className="progress-value">
                  <span style={{ fontWeight: "bold", color: "white" }}>
                    {" "}
                    {watchTime}
                  </span>
                  <span>/4000</span>
                </div>
                <div className="progress-label">Watch Time</div>
              </div>
              <div className="progress-container">
                <div
                  className="progress-fill"
                  style={{
                    width: `${watchTime ? (watchTime / 4000) * 100 : 0}%`,
                  }}
                ></div>
              </div>
            </div>
          )}
          {progressData?.map((item, index) => (
            <ProgressBar
              key={index}
              loading={loading}
              title={item.title}
              count={item.count}
            />
          ))}
          {CustomPieChart && <CustomShapePieChart />}
          {barchart && (
            <div className="chart-container">
              <div className="chart">
                {/* Render bars dynamically here */}

                {timesToPost.map((time, index) => (
                  <div
                    key={index}
                    className="bar"
                    style={{ height: `${(time / 24) * 100}%` }}
                  ></div>
                ))}
              </div>

              {/* Previous day button */}
              <div className="button-container">
                <button
                  disabled={disabled}
                  className={`arrowButton2 prev ${true ? "not-allowed" : ""} `}
                  onClick={() => changeDay("prev")}
                >
                  &#8592;
                </button>
                {/* Current selected day */}
                <h4>{selectedDay}</h4>
                {/* Next day button */}
                <button
                  disabled={disabled}
                  className={`arrowButton2 next ${true ? "not-allowed" : ""} `}
                  onClick={() => changeDay("next")}
                >
                  &#8594;
                </button>
              </div>
            </div>
          )}
          {demographics && (
            <>
              <div
                className={`subtitle ${loading ? "blink access-filter" : ""} `}
              >
                {`Male-Female Ratio: ${
                  genderAgeData?.male?.totalPercentage || 60
                }-${genderAgeData?.female?.totalPercentage || 40}%`}
              </div>
              <div
                className={`demographics ${
                  loading ? "blink access-filter" : ""
                }`}
              >
                <div
                  className="bardemo male-bar"
                  style={{
                    width: `${genderAgeData?.male?.totalPercentage || 60}%`,
                  }}
                >
                  {genderAgeData?.male?.totalPercentage || 60}%
                </div>

                <div
                  className="bardemo female-bar"
                  style={{
                    width: `${genderAgeData?.female?.totalPercentage || 40}%`,
                  }}
                >
                  {genderAgeData?.female?.totalPercentage || 40}%
                </div>
              </div>
            </>
          )}
          {/* {countryWiseData && (
            <div className="pie-chart">
              {countryWiseData.map((data, index) => (
                <div
                  key={index}
                  className="slice"
                  style={{
                    transform: `rotate(${getSliceRotation(
                      countryWiseData,
                      index
                    )}deg)`,

                    backgroundColor: data.color,
                  }}
                >
                  <div
                    className="tooltip"
                    style={{ transform: "translate(100%, 0)" }}
                  >
                    {`${data.country}: ${data.percentage}%`}
                  </div>
                </div>
              ))}
            </div>
          )} */}

          {/* {retention && (
          <div className="progress-bar">
            <div className="progress-details">
              <div className="progress-value">12</div>
              <div className="progress-label">Retention</div>
            </div>
            <div className="progress-container">
              <div
                className="progress-fill"
                style={{ width: `${retention * 10}%` }}
              ></div>
            </div>
          </div>
        )} */}
        </div>

        {inputPlaceholder && (
          <div className="inputField ">
            <input
              className="input "
              type="text"
              placeholder={inputPlaceholder}
            />
            <button className="arrowButton not-allowed">&#8594;</button>
          </div>
        )}
        {ChannelStats && (
          <div className="views-section">
            {ViewscountRatio && (
              <div
                className={`views-item ${loading ? "blink access-filter" : ""}`}
              >
                {" "}
                <div className="views-label">Views</div>
                <div className="views-percentage">{ViewscountRatio}</div>
              </div>
            )}
            {subscribersRatio && (
              <div
                className={`views-item ${loading ? "blink access-filter" : ""}`}
              >
                <div className="views-label">Subscribers</div>
                <div className="views-percentage">{subscribersRatio}</div>
              </div>
            )}
            {watchTimeRatio && (
              <div
                className={`views-item ${loading ? "blink access-filter" : ""}`}
              >
                {" "}
                <div className="views-label">Watch time</div>
                <div className="views-percentage">{watchTimeRatio}</div>
              </div>
            )}
            {retentionRatio && (
              <div
                className={`views-item ${loading ? "blink access-filter" : ""}`}
              >
                {" "}
                <div className="views-label">Retention</div>
                <div className="views-percentage">{retentionRatio}</div>
              </div>
            )}
          </div>
        )}
      </div>
      {cta && <CtaBox />}
    </>
  );
};

TileSquareComponent.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  subscribersRatio: PropTypes.number.isRequired,
  watchTimeRatio: PropTypes.number.isRequired,
  cta: PropTypes.bool.isRequired,
  countryWiseData: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string.isRequired,
      percentage: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      rotation: PropTypes.number.isRequired,
    })
  ),
};

export default TileSquareComponent;
